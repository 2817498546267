import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { VueFire, VueFireAuth  } from 'vuefire'
import router from './router'
import { firebaseApp } from '@/root/firebase';
import Notifications from '@kyvg/vue3-notification'
import { createPersistedState  } from 'pinia-plugin-persistedstate'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Main CSS Add
import './assets/app.css'

import Maska from 'maska'

const app = createApp(App);
const pinia = createPinia();
pinia.use(createPersistedState({
  auto: true
}))

/*
or for SSR:
import Notifications from 'vue-notification/dist/ssr.js'
*/

app.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
      VueFireAuth
    ],
  })
app.use(router);
app.use(pinia);
app.use(Notifications)
app.use(Maska)
app.mount('#app');