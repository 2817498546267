<template>
   <section id="tour_booking_submission" class="section_padding" v-if="reservationRequest">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="tou_booking_form_Wrapper">
                        <div class="booking_tour_form">
                            <h3 class="heading_theme">Falta Pouco</h3>
                            <div class="">
                                <div class="row">
                                    <div class="col-md-8">
                                    <div class="theme_common_box_two img_hover flex d-flex">
                                        <div class="theme_two_box_img">                                      
                                            <img :src="reservationRequest.photos[0]" style="height: 100% !important; width: 200px;" alt="img">
                                            <p><i class="fas fa-map-marker-alt"></i>{{ reservationRequest.city }}</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details"> {{ reservationRequest.name }} </router-link></h4>
                                            <h6>{{reservationRequest.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</h6>
                                            <h3 class="mt-3">{{ reservationRequest.member_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }} /Diaria </h3>
                                            para membros
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="booking_tour_form">
                            <h3 class="heading_theme">Metodo de pagamento</h3>
                            <div class="tour_booking_form_box">
                                <div class="booking_payment_boxed">
                                    <form action="!#" id="payment_checked">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault1" v-model="reservationRequest.formOfPayment" value="Cartão">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Pagamento por cartão
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="reservationRequest.formOfPayment" value="Pix" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault3">
                                            <label class="form-check-label" for="flexRadioDefault3">
                                                PIX
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input v-model="reservationRequest.formOfPayment" value="No Checkout" class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault4">
                                            <label class="form-check-label" for="flexRadioDefault4">
                                                Pagamento no checkout
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> -->
                        <div class="booking_tour_form_submit">
                            <div class="form-check write_spical_check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefaultf1">
                                <label class="form-check-label" for="flexCheckDefaultf1">
                                    <span class="main_spical_check">
                                        <span> Li e aceito todos os  <router-link to="/#">Termos e Condições</router-link></span>
                                    </span>
                                </label>
                            </div>
                            <button @click="confirmReservation()" class="btn btn_theme btn_md my-3"> CONFIRMAR RESERVA </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="tour_details_right_sidebar_wrapper">
                        <!-- <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>Hotel castle salam</h3>
                                </div>
                                <div class="valid_date_area">
                                    <div class="valid_date_area_one">
                                        <h5>Válido de</h5>
                                        <p>03 Jan 2024</p>
                                    </div>
                                    <div class="valid_date_area_one">
                                        <h5>Válida até</h5>
                                        <p>12 Jan 2024</p>
                                    </div>
                                </div>
                                <div class="tour_package_details_bar_list">
                                    <h5>instalações</h5>
                                    <ul>
                                        <li><i class="fas fa-circle"></i>Café da manhã buffet conforme Itinerário</li>
                                        <li><i class="fas fa-circle"></i>Visite oito aldeias que mostram a cultura polinésia
                                            cultura
                                        </li>
                                        <li><i class="fas fa-circle"></i>Churrasco gratuito, fogueira,</li>
                                        <li><i class="fas fa-circle"></i>Todos os impostos de pedágio, estacionamento, combustível e motorista
                                            subsídios
                                        </li>
                                        <li><i class="fas fa-circle"></i>Quartos confortável e higiênico</li>
                                    </ul>
                                </div>
                                <div class="tour_package_details_bar_price">
                                    <h5>Preço</h5>
                                    <div class="tour_package_bar_price">
                                        <h6><del>R$ 130,00</del></h6>
                                        <h3>R$ 60,00 <sub>/Diaria e por pessoa</sub></h3>
                                        </div>
                                        <h3 class="text-body mt-1"> <small> Valor total: </small> <strong> R$ 960,00 </strong></h3>
                                    </div>
                            </div>
                        </div>
                        <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>Data de viagem</h3>
                                </div>
                                <div class="edit_date_form">
                                    <div class="form-group">
                                        <label for="dates">Editar data</label>
                                        <input type="date" id="dates" value="2024-01-03" class="form-control">
                                    </div>
                                </div>
                                <div class="tour_package_details_bar_list">
                                    <h5>Turista</h5>
                                    <div class="select_person_item">
                                        <div class="select_person_left">
                                            <h6>Adulto</h6>
                                            <p>12 anos +</p>
                                        </div>
                                        <div class="select_person_right">
                                            <h6>04</h6>
                                        </div>
                                    </div>

                                    <div class="select_person_item">
                                        <div class="select_person_left">
                                            <h6>Crianças</h6>
                                            <p>2 - 12 anos</p>
                                        </div>
                                        <div class="select_person_right">
                                            <h6>00</h6>
                                        </div>
                                    </div>
                                    <div class="select_person_item">
                                        <div class="select_person_left">
                                            <h6>Infantil</h6>
                                            <p>Até 2 anos</p>
                                        </div>
                                        <div class="select_person_right">
                                            <h6>00</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_person">
                                    <p>Editar pessoa</p>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>Cupom</h3>
                                </div>
                                <div class="coupon_code_area_booking">
                                    <form action="#!">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg_input"
                                                placeholder="Digite o codigo do cupom">
                                        </div>
                                        <div class="coupon_code_submit">
                                            <button class="btn btn_theme btn_md">Aplicar cupom</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div> -->
                        <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>Resumo da Reserva</h3>
                                </div>

                                <div class="tour_booking_amount_area">
                                    <ul>
                                        <li>Reserva ID: <span>#{{ reservationRequest.indentify }}</span></li>
                                        <li>Data de entrada: <span> {{ moment(reservationRequest.entryDate)?.format('DD/MM/yyyy') }}</span></li>
                                        <li>Data de saída: <span> {{ moment(reservationRequest.departureDate)?.format('DD/MM/yyyy') }}</span></li>
                                        <li>Forma de pagamento: <span> {{ reservationRequest.formOfPayment }} </span></li>
                                        <li>Status da reserva: <span> {{ reservationRequest.status }} </span></li>
                                        <li>Diarias: <span> {{ !reservationRequest.dates?.length ? 0 : reservationRequest.dates?.length - 1 }} </span></li>
                                    </ul>
                                    <ul>
                                        <li>Preço por pessoa x {{ reservationRequest.totalPerson }} <span>
                                            {{ 
                                                validPlan(account.plan, reservationRequest.plan) ? reservationRequest.member_price?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                : reservationRequest.price?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                            }} </span></li>
                                    </ul>
                                    <div class="total_subtotal_booking">
                                        <h6 class="remove_coupon_tour"> Valor total <span> {{ reservationRequest.total?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }} </span> </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
import {  useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import { hotelsStore } from '@/store/hotels.store';
import moment from "moment";
import { accountStore } from '@/store/account.store';
import {  DB_UPDATE_PROFILE, UPDATE_INVITE } from "@/root/firebase";
import 'moment/locale/pt-br'
moment.locale('pt-br');

const { account } = storeToRefs(accountStore());
const router = useRouter();
const { reservationRequest } = storeToRefs(hotelsStore());

const confirmReservation = async () => {
    const { reservations } = account.value || {};

    if (!reservations) account.value.reservations = [];
    
    account.value.reservations.push(reservationRequest.value);

    if (account.value?.cupom) {
        account.value.cupom = false;
        account.value.plan = '';
        account.value.plan_unlocked = false;
        account.value.plan_valid = false;
        UPDATE_INVITE(account.value.plan_indentify, { validy: false });
        account.value.plan_indentify = '';
    }

   try {
    await DB_UPDATE_PROFILE(account.value.indentify, account.value);
    
    reservationRequest.value = null;
    router.push('/booking-confirmation')
   } catch(err) {
    console.log('error ao reservar', err)
   }
}


const validPlan = (plan, hotelPlan) => {

  if (!account.value.plan_unlocked) return false;

  const plans = [
    'bronze',
    'prata',
    'ouro'
  ]

  let level = 1;
  let accountLevel = 0;

  if (hotelPlan == 'bronze') level = 1;
  if (hotelPlan == 'prata') level = 2;
  if (hotelPlan == 'ouro') level = 3;
  
  if (plan == 'bronze') accountLevel = 1;
  if (plan == 'prata') accountLevel = 2;
  if (plan == 'ouro') accountLevel = 3;

  if (accountLevel >= level) {
    return true;
  } 
  return false;
}

</script>