<template>
    <section id="about_us_top" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="about_us_left">
                        <h5>{{ about_us }}</h5>
                        <h2>{{ title }}</h2>
                        <p>{{ aboutInfo }} </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>

export default {
    name: "AboutInfo",
    data() {
        return {
            about_us: 'Sobre Nós',
            title: 'Somos o Clube Kaboo',
            aboutInfo: `O Clube Kaboo é uma renomada empresa de hospedagem e associação, consolidada por sua extensa experiência no setor de turismo. Nosso Clube  oferece aos sócios e seus familiares a oportunidade de desfrutar de hospedagens em residências e apartamentos completamente mobiliados, com tarifas econômicas, em destinos turísticos em todo o Brasil. Além disso, proporcionamos vantagens exclusivas, como descontos em diversos parceiros de mercado.
Desde sua fundação em 2011, a empresa tem se destacado continuamente no cenário turístico. Atualmente, introduzimos inovações através do nosso clube de férias, reforçando os pilares de confiança, qualidade e segurança para nossos valorizados sócios.
Estamos comprometidos em proporcionar experiências excepcionais, e esperamos continuar a ser a escolha preferida para aqueles que buscam uma abordagem diferenciada no planejamento de suas viagens e momentos de lazer.`,
            findTour: 'Find tours'
        };
    }
};
</script>