import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TourSearchView from '../views/TourSearchView.vue'
import HotelSearchView from '../views/HotelSearchView.vue'
import HotelDetailsView from '../views/HotelDetailsView.vue'
import RoomBookingView from '../views/RoomBookingView.vue'
import AboutView from '../views/AboutView.vue'
import BookingConfirmationView from '../views/BookingConfirmationView.vue'
import RegisterView from '../views/RegisterView.vue'
import UnlockView from '../views/UnlockView.vue'
import InviteView from '../views/InviteView.vue'
import BecomeVendorView from '../views/BecomeVendorView.vue'
import NotFound from '../components/NotFound.vue'
import ProfileLayoutVue from '@/layouts/ProfileLayout.vue'
import ProfileViewVue from '@/views/ProfileView.vue'
import HomeLayout from '@/layouts/HomeLayout.vue'
import Regulation from '@/components/profile/Regulation.vue'
import Account from '@/components/profile/Account.vue'
import Dependents from '@/components/profile/Dependents.vue'
import Credits from '@/components/profile/Credits.vue'
import Admin from '@/components/profile/Admin.vue'

const routes = [
  {
    path: '/',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      }
    ]
  },
  {
    path: '/tour-search',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'tour-search',
        component: TourSearchView
      }
    ]
  },
  {
    path: '/hotel-search',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'hotel-search',
        component: HotelSearchView
      }
    ]
  },
  {
    path: '/hotel-details',
    component: HomeLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: ':indentify',
        name: 'hotel-details',
        component: HotelDetailsView
      }
    ]
  },
  {
    path: '/room-booking',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'room-booking',
        component: RoomBookingView
      }
    ]
  },
  {
    path: '/about',
    component: HomeLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: 'about',
        component: AboutView
      },
    ]
  },
  {
    path: '/booking-confirmation',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'booking-confirmation',
        component: BookingConfirmationView
      }
    ]
  },
  {
    path: '/register',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'register',
        component: RegisterView
      }
    ]
  },
  {
    path: '/unlock',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'unlock',
        component: UnlockView
      }
    ]
  },
  {
    path: '/invite',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'invite',
        component: InviteView
      }
    ]
  },
  {
    path: '/become-vendor',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'become-vendor',
        component: BecomeVendorView
      }
    ]
  },
  {
    path: '/profile',
    component: ProfileLayoutVue,
    meta: { requiresAuth: true },
    redirect: '/profile/reservations',
    children: [
      {
        path: '/profile/reservations',
        name: 'profile',
        component: ProfileViewVue,
      },
      {
        path: '/profile/regulation',
        name: 'regulation',
        component: Regulation,
      },
      {
        path: '/profile/account',
        name: 'account',
        component: Account,
      },
      {
        path: '/profile/dependents',
        name: 'dependents',
        component: Dependents,
      },
      {
        path: '/profile/credits',
        name: 'credits',
        component: Credits,
      },
      {
        path: '/profile/admin',
        name: 'admin',
        component: Admin,
      }
    ]
  },
  {
    path: '/:cathAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]


const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition || to.meta?.scrollPos || { top: 0, left: 0 }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
})

router.beforeEach((to, from, next) => {
  from.meta?.scrollPos && (from.meta.scrollPos.top = window.scrollY)

  const userIsAuthenticated = window.localStorage.getItem('token');
  const requiresAuth = to.matched.some((route) => route.meta && route.meta.requiresAuth);

  if (!userIsAuthenticated && to.fullPath === '/profile') {
    return next('/become-vendor');
  }

  if (!userIsAuthenticated && requiresAuth) {
    return next('/become-vendor');
  }

  return next();
})


export default router