<template>
  <section
    id="dashboard_main_arae"
    class="section_padding section_padding--mini"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="dashboard_common_table">
            <h3>Meus Creditos</h3>
            <span class="mt-3 d-block font-monospace text-secondary">
              Nenhum Credito foi encontrado...
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Dependents",
  components: {},
};
</script>
    
<style scoped>
tr,
th {
  text-align: left;
}
td {
  font-size: 0.9rem !important;
  text-align: left;
}

.space {
  padding: 0.5rem 0px;
}
</style>