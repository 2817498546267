<template>
  <section id="related_tour_packages" class="section_padding_bottom">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>Hotéis relacionados</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="promotional_tour_slider owl-theme owl-carousel dot_style">
            <swiper
              :slides-per-view="4"
              :space-between="20"
              :pagination="{ clickable: true }"
            >
              <swiper-slide>
                <div class="theme_common_box_two img_hover">
                  <div class="col-12">
                    <div class="theme_common_box_two img_hover">
                      <div class="theme_two_box_img">
                        <router-link to="/hotel-details">
                          <img
                            src="../../assets/img/hotel/hotel1.png"
                            alt="img"
                          />
                        </router-link>
                        <p>
                          <i class="fas fa-map-marker-alt"></i>Rio de Janeiro
                        </p>
                      </div>
                      <div class="theme_two_box_content">
                        <h4>
                          <router-link to="/hotel-details"
                            >Kantua hotel, Thailand</router-link
                          >
                        </h4>
                        <p>
                          <span class="review_rating"
                            ><i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i> 4.5/5
                            Excelente</span
                          >
                        </p>
                        <h3>R$190,00 <span>Por noite</span></h3>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="theme_common_box_two img_hover">
                  <div class="col-12">
                    <div class="theme_common_box_two img_hover">
                      <div class="theme_two_box_img">
                        <router-link to="/hotel-details">
                          <img
                            src="../../assets/img/hotel/hotel2.png"
                            alt="img"
                          />
                        </router-link>
                        <p>
                          <i class="fas fa-map-marker-alt"></i>Rio de Janeiro
                        </p>
                      </div>
                      <div class="theme_two_box_content">
                        <h4>
                          <router-link to="/hotel-details"
                            >Kantua hotel, Thailand</router-link
                          >
                        </h4>
                        <p>
                          <span class="review_rating"
                            ><i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i> 4.5/5
                            Excelente</span
                          >
                        </p>
                        <h3>R$190,00 <span>Por noite</span></h3>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="theme_common_box_two img_hover">
                  <div class="col-12">
                    <div class="theme_common_box_two img_hover">
                      <div class="theme_two_box_img">
                        <router-link to="/hotel-details">
                          <img
                            src="../../assets/img/hotel/hotel3.png"
                            alt="img"
                          />
                        </router-link>
                        <p>
                          <i class="fas fa-map-marker-alt"></i>Rio de Janeiro
                        </p>
                      </div>
                      <div class="theme_two_box_content">
                        <h4>
                          <router-link to="/hotel-details"
                            >Kantua hotel, Thailand</router-link
                          >
                        </h4>
                        <p>
                          <span class="review_rating"
                            ><i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i> 4.5/5
                            Excelente</span
                          >
                        </p>
                        <h3>R$190,00 <span>Por noite</span></h3>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="theme_common_box_two img_hover">
                  <div class="col-12">
                    <div class="theme_common_box_two img_hover">
                      <div class="theme_two_box_img">
                        <router-link to="/hotel-details">
                          <img
                            src="../../assets/img/hotel/hotel1.png"
                            alt="img"
                          />
                        </router-link>
                        <p>
                          <i class="fas fa-map-marker-alt"></i>Rio de Janeiro
                        </p>
                      </div>
                      <div class="theme_two_box_content">
                        <h4>
                          <router-link to="/hotel-details"
                            >Kantua hotel, Thailand</router-link
                          >
                        </h4>
                        <p>
                          <span class="review_rating"
                            ><i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i> 4.5/5
                            Excelente</span
                          >
                        </p>
                        <h3>R$190,00 <span>Por noite</span></h3>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="theme_common_box_two img_hover">
                  <div class="col-12">
                    <div class="theme_common_box_two img_hover">
                      <div class="theme_two_box_img">
                        <router-link to="/hotel-details">
                          <img
                            src="../../assets/img/hotel/hotel2.png"
                            alt="img"
                          />
                        </router-link>
                        <p>
                          <i class="fas fa-map-marker-alt"></i>Rio de Janeiro
                        </p>
                      </div>
                      <div class="theme_two_box_content">
                        <h4>
                          <router-link to="/hotel-details"
                            >Kantua hotel, Thailand</router-link
                          >
                        </h4>
                        <p>
                          <span class="review_rating"
                            ><i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i> 4.5/5
                            Excelente</span
                          >
                        </p>
                        <h3>R$190,00 <span>Por noite</span></h3>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="theme_common_box_two img_hover">
                  <div class="col-12">
                    <div class="theme_common_box_two img_hover">
                      <div class="theme_two_box_img">
                        <router-link to="/hotel-details">
                          <img
                            src="../../assets/img/hotel/hotel3.png"
                            alt="img"
                          />
                        </router-link>
                        <p>
                          <i class="fas fa-map-marker-alt"></i>Rio de Janeiro
                        </p>
                      </div>
                      <div class="theme_two_box_content">
                        <h4>
                          <router-link to="/hotel-details"
                            >Kantua hotel, Thailand</router-link
                          >
                        </h4>
                        <p>
                          <span class="review_rating"
                            ><i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i> 4.5/5
                            Excelente</span
                          >
                        </p>
                        <h3>R$190,00 <span>Por noite</span></h3>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

export default {
  name: "RelatedHotel",

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiper: null,
    };
  },
};
</script>