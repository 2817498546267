<template>
  <div class="container modal-body">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="vendor_form">
          <div>
            <form @submit.prevent="registerUser()" indentify="profile_form_area">
              <div class="row g-2">
                <div class="col-lg-6">
                  <div class="form-group has-valindentifyation">
                    <label for="f-name">name</label>
                    <input
                      autocomplete="off"
                      v-model="formData.name"
                      type="text"
                      class="form-control"
                      indentify="f-name"
                      placeholder="name"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="mail-address">E-mail</label>
                    <input
                      autocomplete="off"
                      v-model="formData.email"
                      type="text"
                      class="form-control"
                      indentify="mail-address"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="f-name">CPF</label>
                    <input
                      v-model="formData.cpf"
                      type="text"
                      class="form-control"
                      indentify="f-cpf"
                      placeholder="CPF"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="f-name">Parentesco</label>
                    <select
                      v-model="formData.kinship"
                      class="form-control form-select"
                      required
                    >
                      <option selected>Selecione</option>
                      <option value="Amigo / Amiga">Amigo / Amiga</option>
                      <option value="Avô / Avó">Avô / Avó</option>
                      <option value="Cunhado / Cunhada">Cunhado / Cunhada</option>
                      <option value="Filho / Filha">Filho / Filha</option>
                      <option value="Genro / Nora">Genro / Nora</option>
                      <option value="Irmão / Irmã">Irmão / Irmã</option>
                      <option value="Marido / Esposa">Marido / Esposa</option>
                      <option value="Namorado / Namorada">Namorado / Namorada</option>
                      <option value="Neto / Neta">Neto / Neta</option>
                      <option value="Pai / Mãe">Pai / Mãe</option>
                      <option value="Primo / Prima">Primo / Prima</option>
                      <option value="Sobrinho / Sobrinha">Sobrinho / Sobrinha</option>
                      <option value="Sogro / Sogra">Sogro / Sogra</option>
                      <option value="Tio / Tia">Tio / Tia</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="f-name">Data de nascimento</label>
                    <input
                      v-model="formData.date_birth"
                      type="text"
                      class="form-control"
                      indentify="f-name"
                      placeholder="Data de nascimento"
                      required
                      v-maska
                      data-maska="##/##/####"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="Celular">Celular</label>
                    <input
                      v-model="formData.phone"
                      type="text"
                      class="form-control"
                      indentify="Celular"
                      required
                      v-maska
                      data-maska="## # ####-####"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="f-sex" class="d-block">sex</label>
                    <div class="form-check form-check-inline">
                      <input
                        v-model="formData.sex"
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        indentify="inlineRadio1"
                        value="Feminino"
                      />
                      <label class="form-check-label" for="inlineRadio1"
                        >Feminino</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        v-model="formData.sex"
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        indentify="inlineRadio2"
                        value="Masculino"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                        >Masculino</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        v-model="formData.sex"
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        indentify="inlineRadio3"
                        value="Outros"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                        >Outros</label
                      >
                    </div>
                  </div>
                </div>
                <div class="change_password_input_boxed" v-if="!prop.dependent">
                  <h3 class="mt-3 mb-2">Adicionar Senha</h3>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="formData.password"
                          type="password"
                          class="form-control"
                          placeholder="password"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="formData.confirmPassword"
                          type="password"
                          class="form-control"
                          placeholder="Repita a password"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Cancelar
                </button>
                <button type="submit" class="btn btn-primary">
                    {{ prop.dependent ? 'Editar Dependente'  : 'Inserir Cadastro' }}
                </button>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineEmits, watch } from "vue";
import { accountStore } from "@/store/account.store";
import { Timestamp, CREATE_USER, db, SIGN_OUT_DEP } from "@/root/firebase";
import { notify } from "@kyvg/vue3-notification";
import { vMaska } from "maska";
import { storeToRefs } from "pinia";

const emit = defineEmits(["closed"]);
const prop = defineProps(['dependent']);


const { account, listDependents } = storeToRefs(accountStore());


const formData = reactive({
  indentify: "",
  name: "",
  date_created: "",
  email: "",
  cpf: "",
  kinship: "",
  date_birth: "",
  phone: "",
  sex: "",
  password: "",
  confirmPassword: "",
});

watch(
  () => prop.dependent,
  (dependent) => {
    if (dependent) {
        formData['indentify'] = dependent.indentify;
        formData['name'] = dependent.name;
        formData['email'] = dependent.email;
        formData['cpf'] = dependent.cpf;
        formData['kinship'] = dependent.kinship;
        formData['date_birth'] = dependent.date_birth;
        formData['phone'] = dependent.phone;
        formData['sex'] = dependent.sex;
        formData['password'] = dependent.password;
        formData['confirmPassword'] = dependent.password;
        formData['date_created'] = dependent.date_created.seconds ? dependent.date_created?.toDate() : dependent.date_created; 
    } else {
        formData['indentify'] = '';
        formData['name'] = '';
        formData['email'] = '';
        formData['cpf'] = '';
        formData['kinship'] = '';
        formData['date_birth'] = '';
        formData['phone'] = '';
        formData['sex'] = '';
        formData['password'] = '';
        formData['confirmPassword'] = '';
        formData['date_created'] = ''; 
    }
  }
)

const addDependent = () => {
  formData['indentify'] = '';
  formData['name'] = '';
  formData['email'] = '';
  formData['cpf'] = '';
  formData['kinship'] = '';
  formData['date_birth'] = '';
  formData['phone'] = '';
  formData['sex'] = '';
  formData['password'] = '';
  formData['confirmPassword'] = '';
  formData['date_created'] = '';

  emit("closed");
};

const registerUser = () => {
  if (listDependents.value.length >= 4) return;

  if (formData.password !== formData.confirmPassword) {
    return notify({
      title: "Error na password",
      text: "passwords não corresponde",
      type: "error",
    });
  }

  const add = async (user) => {
    const data = JSON.parse(JSON.stringify(formData));
    delete data.confirmPassword;

      if (user) {
        data.indentify = user.uid;
        data.date_created = Timestamp.fromDate(new Date())
      }

      data.dep = true;
      data.plan = account.value.plan
      data.plan_indentify = account.value.plan_indentify;
      data.plan_valid = account.value.plan_valid;

      await SIGN_OUT_DEP(account, user?.uid || data.indentify, data);

      if (!user) {
        listDependents.value = listDependents.value.map((el) => {
          if (el.indentify === data.indentify) {
            el = data;
          }
          return el;
        })
      }
      addDependent();
  }

  if (prop.dependent) return add()

  CREATE_USER(formData.email, formData.password, account)
    .then(async (user) => {
      add(user)
    })
    .catch((error) => {
      console.error("Erro ao criar usuário:", error);
      notify({
        title: "Error ao cadastrar",
        text: 'Conta de dependente já cadastrado com esse e-mail ou algo errado.',
        type: "error",
      });
    });
};
</script>