<template>
  <section id="vendor_form_area" class="section_padding_bottom">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="vendor_form_heading text-center">
            <h2>{{ title }}</h2>
            <p class="px-5">{{ subTitle }}</p>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="vendor_form">
            <div class="tour_booking_form_box">
              <form action="!#" id="tour_bookking_form_item">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.name"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Nome Completo*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.phone"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Celular*"
                        id="phone"
                        v-maska
                        data-maska="## # ####-####"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.cpf"
                        type="text"
                        class="form-control bg_input"
                        placeholder="CPF*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.rg"
                        type="text"
                        class="form-control bg_input"
                        placeholder="RG*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.profession"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Profissão"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.spouse"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Cônjuge"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.email"
                        type="email"
                        class="form-control bg_input"
                        placeholder="E-mail"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.city"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Cidade*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.password"
                        type="password"
                        class="form-control bg_input"
                        placeholder="Senha*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.confirmPassword"
                        type="password"
                        class="form-control bg_input"
                        placeholder="Confirmar senha*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <select
                        v-model="dataRegister.contactPreference"
                        class="form-control bg_input form-select"
                        aria-label="Preferência de contato?"
                      >
                        <option value="nenhum" selected>Preferência de contato?</option>
                        <option value="Telefone">Pelo Telefone</option>
                        <option value="Whatsapp">Pelo Whatsapp</option>
                        <option value="E-mail">Pelo E-mail</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="booking_tour_form_submit pt-4">
              <div class="form-check write_spical_check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefaultf1"
                />
                <label class="form-check-label" for="flexCheckDefaultf1">
                  Eu li e aceito os
                  <router-link to="/"> termos de condições </router-link> e
                  <router-link to="/">politica de privacidade</router-link>
                </label>
              </div>
              <button
                class="btn btn-primary my-4"
                @click="registerUser()"
                :disabled="!validation()"
              >
                Cadastrar
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="col-lg-12 mb-4">
            <div class="about_service_boxed">
              <img src="../../assets/img/icon/persentis.png" alt="img" />
              <h5><a href="#!">Ofertas incríveis</a></h5>
              <p>
                Phaseus site amet tristique ligua donec iaculis leo sus cipit.
                Consec tetur adipiscing elit. Incididunt ut dolore.
              </p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="about_service_boxed">
              <img src="../../assets/img/icon/world.png" alt="img" />
              <h5><a href="#!">Melhores serviços</a></h5>
              <p>
                Phaseus site amet tristique ligua donec iaculis leo sus cipit.
                Consec tetur adipiscing elit. Incididunt ut dolore.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { DB_UPDATE_PROFILE, Timestamp, CREATE_USER } from "@/root/firebase";
import { notify } from "@kyvg/vue3-notification";
import { vMaska } from "maska";

const router = useRouter();

const dataRegister = reactive({
  name: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  city: "",
  contactPreference: "nenhum",
  spouse: '',
  profession: '',
  cpf: '',
  rg: '',
});

const title = "Torne-se um associado";
const subTitle = `Aqui no Clube Kaboo, temos hospedagens para todos os gostos. Seja campo, serra ou praia, centro ou interior, temos sempre uma ótima opção para você e sua familia!`;

const validation = () => {
  if (
    dataRegister.name &&
    dataRegister.email &&
    dataRegister.phone &&
    dataRegister.password &&
    dataRegister.confirmPassword &&
    dataRegister.city &&
    dataRegister.contactPreference &&
    dataRegister.cpf && 
    dataRegister.profession &&
    dataRegister.rg
  )
    return true;

  return false;
};

const registerUser = () => {
  if (dataRegister.password !== dataRegister.confirmPassword) {
    return notify({
      title: "Error na senha",
      text: "Senhas não corresponde",
      type: "error",
    });
  }
  CREATE_USER(dataRegister.email, dataRegister.password)
    .then(async (user) => {
      delete dataRegister.confirmPassword;

      dataRegister.date_created = Timestamp.fromDate(new Date());
      dataRegister.plan = null;
      dataRegister.indentify = user.uid;
      dataRegister.dep = false;
      dataRegister.plan = null;
      dataRegister.plan_indentify = null;
      dataRegister.plan_valid = null;
      dataRegister.password = dataRegister.password;

      await DB_UPDATE_PROFILE(user.uid, dataRegister);
      localStorage.setItem('token', user.accessToken);

      router.push({name: 'profile'});
    })
    .catch((error) => {
      console.error("Erro ao criar usuário:", error);
      notify({
        title: "Error ao cadastrar",
        text: error,
        type: "error",
      });
    });
};
</script>