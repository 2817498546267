<template>
  <header class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <nav class="navbar navbar-expand-md navbar-light">
            <router-link class="" to="/">
              <h2 class="text-primary">Clube Kaboo</h2>
            </router-link>
          </nav>
        </div>
        <div class="col-md-6 text-end">
          <h4 class="text-body">
            Olá, {{ account?.name }}
            <span
              v-if="account?.plan_indentify && !account?.admin"
              :class="account?.plan"
              class="medal-r prata"
            >
              <i class="fas fa-medal" style="font-size: 1.5rem"></i>
            </span>
          </h4>
          <span v-if="!account?.admin && account?.plan_unlocked && account?.plan_indentify" class="d-block text-black-50 fs-6 fw-light">
            seu {{ account?.cupom ? 'cupom' : 'código' }} de associado: <strong class="text-primary">{{ account?.plan_indentify }}</strong>
          </span>
          <span v-if="!account?.plan_indentify">
            Não Associado
          </span>
          <span v-if="!account?.admin && !account?.plan_unlocked && account?.plan_indentify" class="d-block text-black-50 fs-6 fw-light">
            <i class="fas fa-lock"></i> <strong class="text-primary">{{ account?.plan_indentify }}</strong>
           <strong>
            Aguardando desbloqueio 
           </strong>
          </span>
          <h2 v-if="account?.admin" class="title text-primary">
            ADMIN
          </h2>
        </div>
      </div>
    </div>
  </header>
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-8">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/profile/reservations"><i class="fa fa-bars" aria-hidden="true"></i> Reservas </router-link>
          </li>
          <li class="nav-item" v-if="!account?.admin">
            <router-link class="nav-link" to="/profile/credits"><i class="fa fa-user-circle" aria-hidden="true"></i> Meus créditos </router-link>
          </li>
          <li class="nav-item" v-if="!account?.admin">
            <router-link class="nav-link" to="/profile/regulation"><i class="fa fa-info-circle" aria-hidden="true"></i> Regulamento </router-link>
          </li>
          <li class="nav-item" v-if="account?.plan_indentify && !account?.dep && account?.plan_unlocked">
            <router-link class="nav-link" to="/profile/dependents"><i class="fa fa-users" aria-hidden="true"></i> Dependentes </router-link>
          </li>
          <li class="nav-item" v-if="!account?.admin && account?.plan_indentify && !account?.dep">
            <a class="nav-link disabled" href="#"><i class="fa fa-certificate" aria-hidden="true"></i> Certificado</a>
          </li>
          <li class="nav-item" v-if="account?.admin">
            <router-link class="nav-link" to="/profile/admin"><i class="fas fa-user-lock"></i> Admin </router-link>
          </li>
        </ul>
      </div>
      <div class="col-md-3 text-end">
        <ul class="nav justify-content-end">
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/profile/account"><i class="fa fa-user" aria-hidden="true"></i> Minha Conta </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href.prevent href="#" @click="logout()"><i class="fa fa-user-circle" aria-hidden="true"></i> Sair </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Form style="margin-top: 2rem" />
</template>
<script setup>
import { storeToRefs } from "pinia";
import { accountStore } from "@/store/account.store";
import { useRouter } from 'vue-router';
import Form from '@/components/hotel/Form.vue';
import { SIGN_OUT } from "@/root/firebase";

const router = useRouter()

const { account } = storeToRefs(accountStore());

const logout = async () => {
 try {
    await SIGN_OUT();
    account.value = null;
    window.localStorage.removeItem('token');
    router.push({path: '/'})
 } catch (err) {
    console.log(err);
 }
}
</script>

<style scoped>
.nav-link {
  color: var(--bs-gray-600)
}
.nav-link.disabled {
  opacity: .6;
}

.router-link-exact-active {
  color: var(--bs-blue);
}
</style>