<template>
  <section id="vendor_form_area" class="section_padding_bottom">
    <div class="container">
      <div class="row justify-content-center align-items-start">
        <div class="col-md-5">
          <h2 class="text-center fs-3 fw-normal text-black-50">
            Sou associado
            <span
              class="d-block fw-bold text-primary"
              style="margin-top: -0.8rem"
            >
              Clube Kaboo
            </span>

            <span v-if="isAccount">
              <strong>
                {{ accountId }}
              </strong>
            </span>

            <span v-if="messageError" class="text-danger d-block">
              {{ messageError }}
            </span>
          </h2>
          <div class="k-card--border">
            <form @submit.prevent action="!#" id="tour_bookking_form_item">
              <div class="row">
                <!-- <div v-if="isAccount" class="col-lg-12 px-md-5">
                  <div class="form-group">
                    <select class="form-control form-select">
                      <option selected value="Titular">Acesso: Titular</option>
                      <option value="Dependente">Acesso: Dependente</option>
                    </select>
                  </div>
                </div> -->
                <div v-if="!isAccount" class="col-lg-12 px-md-5">
                  <div class="form-group">
                    <input
                      v-model="accountId"
                      type="text"
                      class="form-control"
                      placeholder="Digite seu código de associado"
                    />
                  </div>
                </div>
                <!-- <div v-if="!isAccount" class="col-lg-12 px-md-5">
                  <div class="form-group">
                    <div class="form-check form-switch">
                    <input v-model="notPlan" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                    <label class="form-check-label" for="flexSwitchCheckDefault">
                      Não tenho código de acesso, mas sou cadastrado
                    </label>
                  </div>
                  </div>
                </div> -->
                <!-- v-if="isAccount || notPlan" -->
                <div class="col-lg-12 px-md-5" v-if="isAccount">
                  <div class="form-group">
                    <input
                      v-model="email"
                      autocomplete="off"
                      type="email"
                      class="form-control"
                      placeholder="Digite seu e-mail"
                    />
                    <input
                      v-model="password"
                      autocomplete="off"
                      type="password"
                      class="form-control mt-2"
                      placeholder="Digite sua senha"
                    />
                  </div>
                </div>
                <div class="col-12 px-md-5">
                  <a href="#" @click.prevent="isRecovery = !isRecovery" class="d-block mb-3"> Recuperar senha </a>
                  <div v-if="isRecovery" class="w-100 recovery row mb-4 align-items-center items-center">
                   <div class="col-md-9">
                    <input
                      v-model="emailRecovery"
                      autocomplete="off"
                      type="email"
                      class="form-control"
                      placeholder="Digite seu e-mail"
                    />
                   </div>
                    <div class="col-md-3">
                      <button
                        @click="recoveryPassword(emailRecovery)"
                        class="w-100 btn btn_custom"
                      >
                        Enviar
                      </button>
                    </div>
                    <span class="info-recovery mt-2">
                      Você receberá um e-mail com as instruções para recuperar sua senha
                    </span>
                  </div>
                  <div class="d-grid gap-2">
                    <button
                      type="button"
                      @click="!isAccount ? verifyAccount() : login()"
                      class="btn btn_theme btn_md"
                    >
                      {{ !isAccount ? 'Continuar' : 'Entrar' }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-5 px-md-5 card py-md-5">
          <h2 class="text-center fs-3 fw-normal text-success">
            Seja um
            <span
              class="d-block fw-bold text-success"
              style="margin-top: -0.8rem"
            >
              associado
            </span>
          </h2>
          <span
            class="fs-6 fw-light text-center align-center d-block mt-3 mb-1"
            style="padding-bottom: 1rem"
          >
            Ainda não é um associado Kaboo? <br />
            aproveite suas férias com economia e qualidade! <br />
            Temos uma oportunidade especial
          </span>

          <div class="d-grid gap-2">
            <button
              type="button"
              @click="router.push({ name: 'register' })"
              name=""
              id=""
              class="btn btn-success"
            >
              Quero me associar
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script setup>
import { storeToRefs } from "pinia";
import { accountStore } from "@/store/account.store";
import { ref } from "vue";
import { useRouter } from 'vue-router';
import { LOGIN, planIndentify, auth } from "@/root/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { notify } from "@kyvg/vue3-notification";

const router = useRouter();
const isRecovery = ref(false);
const emailRecovery = ref(null);
const accountId = ref("");
const isAccount = ref(false);
const password = ref(null);
const messageError = ref(null);
const notPlan = ref(false);
const { account, listDependents } = storeToRefs(accountStore());
const email = ref('');

const verifyAccount = () => {
  planIndentify(accountId.value)
    .then((doc) => {
      if (!doc.docs.length) {
        notPlan.value = true;
        messageError.value = "Erro na verificação do código de associado"
        return
      }
      const profile = doc.docs[0].data();
      email.value = profile.email;
      isAccount.value = true;
      messageError.value = null;
    })
};


const login = () => {
    LOGIN(email.value, password.value)
    .then((user) => {
      listDependents.value = [];
      localStorage.setItem('token', user.accessToken);
      router.push({name: 'profile'});

    }).catch((error) => {
      notify({
        title: "Erro no login",
        text: error,
        type: "error",
        duration: 7000,
      });
    });
}

const recoveryPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    notify({
        title: "E-mail enviado",
        text: 'E-mail de recuperação enviado com sucesso!',
        type: "success",
        duration: 7000,
    });
  } catch (error) {
    notify({
        title: "Erro na recuperação",
        text: error,
        type: "error",
        duration: 7000,
    });
  } finally {
    isRecovery.value = false
  }
}
</script>

<style scoped>
.btn_custom {
  background: var(--bs-primary) !important;
  color: #fff !important;
}
.info-recovery {
  font-size: .8rem;
  color: #9b9b9b
}
</style>