<template>
  <router-view />
  <notifications position="bottom right" :duration="3000" />
</template>

<script setup>
import { ref } from 'vue';
import { getProfile, auth, GET_TITLE, DB_UPDATE_PROFILE } from './root/firebase';
import { accountStore } from "@/store/account.store";
import { onAuthStateChanged } from "firebase/auth";

const { setAccount } = accountStore();
const titleData = ref('');

onAuthStateChanged(auth, async (user) => {
  if (user) {
    const account = await getProfile(user.uid);
    const title = account.plan_indentify;

    if (!title || title && title?.unlocked && title?.validy) {
      setAccount(account);
      return;
    }

    await GET_TITLE(title).then(_title => {
       titleData.value = _title.data();
       if (titleData.value?.validy) {
          account.plan_unlocked = titleData.value?.unlocked;
          account.plan_valid = titleData.value?.validy;
       }
       
       setAccount(account);
    })

    DB_UPDATE_PROFILE(account.indentify, account);
  } else {
  }
});

</script>