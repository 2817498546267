<template>
  <section id="home_banner">
    <div class="swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide-desktop"><img src="@/assets/capa/slide1.gif" /></div>
        <div class="swiper-slide slide-desktop"><img src="@/assets/capa/slide3.png" /></div>
        <div class="swiper-slide slide-desktop"><img src="@/assets/capa/slide4.png" /></div>
        <div class="swiper-slide slide-desktop"><img src="@/assets/capa/slide5.png" /></div>
        <div class="swiper-slide slide-desktop"><img src="@/assets/capa/slide2.gif" /></div>

        <div class="swiper-slide slide-mobile"><img src="@/assets/capa/mobile/6.png" /></div>
        <div class="swiper-slide slide-mobile"><img src="@/assets/capa/mobile/1.png" /></div>
        <div class="swiper-slide slide-mobile"><img src="@/assets/capa/mobile/2.png" /></div>
        <div class="swiper-slide slide-mobile"><img src="@/assets/capa/mobile/3.png" /></div>
      </div>

      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-pagination"></div>
      <div class="autoplay-progress">
        <svg viewBox="0 0 48 48">
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span></span>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted } from "vue";
onMounted(() => {
  const progressCircle = document.querySelector(".autoplay-progress svg");
  const progressContent = document.querySelector(".autoplay-progress span");
  var swiper = new Swiper(".mySwiper", {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      autoplayTimeLeft(s, time, progress) {
        progressCircle.style.setProperty("--progress", 1 - progress);
        progressContent.textContent = `${Math.ceil(time / 1000)}s`;
      },
    },
  });
});
</script>

<style scoped>
#home_banner {
  margin-top: 0;
  margin-bottom: 6rem;
}
.swiper {
  width: 100%;
  height: 85vh;
  position: relative;
  max-height: 720px;
}

.slide-desktop {
  display: block !important;
}

.slide-mobile {
  display: none !important;
}

@media all and (max-width: 768px) {
  .slide-desktop {
    display: none !important;
  }

  .slide-mobile {
    display: block !important;
  }

  .swiper {
    height: 90vh;
    max-height: 2000px;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 3rem;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 3rem;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}
</style>
