<template>
  <section id="dashboard_main_arae" class="section_padding section_padding--mini">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="dashboard_common_table">
            <h3> Minhas Reservas </h3>
            <div class="table-responsive-lg table_common_area">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="text-center">ID</th>
                    <th></th>
                    <th>data de entrada</th>
                    <th>data de saída</th>
                    <th>diárias | hóspedes</th>
                    <th>Status</th>
                    <th class="text-end">Pagamento &nbsp;&nbsp;</th>
                  </tr>
                </thead>
                <tbody v-if="account?.reservations">
                  <tr v-for="item in account.reservations" :key="item.indentify">
                    <td class="text-center">
                        <div>
                            <img :src="item.photos[0]" style="height: 50px" class="img-thumbnail" alt="...">
                        </div>
                    </td>
                    <td class="text-secondary fs-6">
                        #{{item.indentify}}
                        <span class="d-block">Via Porto {{ item.name }}</span>
                    </td>
                    <td class="text-secondary fs-6"><span class="text-body"> {{ moment(item.entryDate).format('DD/MM/yyyy dddd') }} </span></td>
                    <td class="text-secondary fs-6"><span class="text-body"> {{ moment(item.departureDate).format('DD/MM/yyyy dddd') }} </span></td>
                    <td class="text-secondary fs-6">
                        <span class="text-body"> {{ item.dates?.length }} Diárias</span> para
                        <span class="text-body"> {{ item.totalPerson }} Hóspedis</span>
                        <br />
                        <span v-for="(person, index) in item.person" :key="person">
                          {{ person }} {{ index == 0 ? 'Adultos' : index == 1 ? 'Crianças' :  'Bb´s'}} &nbsp; 
                        </span>
                    </td>
                    <td
                    class="fs-6 complete"
                    :class="item.status">
                        {{  item.status }}
                    </td>
                    <td class="fs-6 text-end"> {{ item.formOfPayment }} - {{ item.total?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }} &nbsp;&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { accountStore } from '@/store/account.store';
import { storeToRefs } from 'pinia';
import moment from 'moment';
import 'moment/locale/pt-br'
moment.locale('pt-br');

const { account } = storeToRefs(accountStore());

</script>

<style scoped>
tr, th {
    text-align: left;
}
td {
    font-size: .9rem !important;
    text-align: left;
}

.Pendente {
  color: #686868 !important;
}
.Realizado {
  color: #14a36c !important;
}
.Cancelado {
  color: #b91b1b !important;
}
</style>