<template>
  <div>
    <header class="main_header_arae">
      <!-- Navbar Bar -->
      <div
        class="navbar-area"
        :class="{
          'is-sticky':
            isSticky ||
            $route.name === 'hotel-details' ||
            $route.name === 'booking-confirmation' ||
            $route.name === 'room-booking' ||
            $route.name === 'become-vendor' ||
            $route.name === 'register' ||
            $route.name === 'unlock' ||
            $route.name === 'invite' ||
            $route.name === 'tour-search' ||
            $route.name === 'about'
        }"
      >
        <!-- menu mobile -->
        <div class="main-responsive-nav py-0">
          <div class="container">
            <div class="main-responsive-menu mean-container">
              <nav class="navbar">
                <div class="container">
                  <div class="logo">
                    <router-link class="" to="/">
                      <h3 class="text-white">Clube Kaboo</h3>
                    </router-link>
                  </div>
                  <button
                    class="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-content"
                  >
                    <div class="hamburger-toggle">
                      <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </button>
                  <div
                    class="collapse navbar-mobile navbar-collapse mean-nav"
                    id="navbar-content"
                    style="margin-top: 1.3rem"
                  >
                    <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                      <li class="nav-item">
                        <router-link to="/about" class="nav-link">
                          Sobre nós
                        </router-link>
                      </li>

                      <li class="nav-item">
                        <router-link to="/pesguntas" class="nav-link"
                          >Perguntas frequestes</router-link
                        >
                      </li>
                    <div class="btn-profile">
                      <router-link
                            :to="!userIsAuthenticated ? '/become-vendor' : 'profile'"
                            class="btn btn_navber ma-2"
                            style="color: #fff; text-align: center"
                          >
                          <div v-if="!userIsAuthenticated">
                            <i class="fas fa-user"></i>
                             Minha conta
                          </div>
                        <div v-else>
                            Olá, {{ account?.name }}
                        </div>
                      </router-link>
                    </div>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <!-- Desktop menu -->
        <div class="main-navbar">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
              <router-link class="" to="/">
                <h2 class="text-white">Clube Kaboo</h2>
              </router-link>

              <div
                class="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <router-link to="/about" class="nav-link">
                      Sobre nós
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="/#" class="nav-link"
                      >Perguntas frequestes</router-link
                    >
                  </li>

                </ul>
                <div class="others-options d-flex align-items-center">
                  <div class="option-item">
                    <router-link
                      :to="!userIsAuthenticated ? '/become-vendor' : 'profile'"
                      class="btn btn_navber"
                    >
                      <div v-if="!userIsAuthenticated">
                        <i class="fas fa-user"></i>
                        Minha conta
                      </div>
                      <div v-else style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis;max-width: 200px">
                        Olá, {{ account?.name }}
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="others-option-for-responsive">
          <div class="container">
            <div class="container">
              <div class="option-inner">
                <div class="others-options d-flex align-items-center">
                  <div class="option-item">
                    <a href="#" class="search-box"
                      ><i class="fas fa-search"></i
                    ></a>
                  </div>
                  <div class="option-item">
                    <router-link to="/become-vendor" class="btn btn_navber">
                      <i class="fas fa-user"></i>
                      Torne-se um associado
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue"
import { accountStore } from "@/store/account.store";

const { account } = storeToRefs(accountStore());

const language = "Português"
const  currency = "REAL"
const  isSticky = ref(false)
const  userIsAuthenticated = ref(null)

onMounted(() => {
  userIsAuthenticated.value = window.localStorage.getItem('token');

    window.addEventListener("scroll", () => {
      let scroll = window.scrollY;

      if (scroll >= 200) {
        isSticky.value = true;
      } else {
        isSticky.value = false;
      }
    });

    document.addEventListener("click", function (e) {
      // Hamburger menu
      if (e.target.classList.contains("hamburger-toggle")) {
        e.target.children[0].classList.toggle("active");
      }
    });
})
</script>

<style scoped>
.navbar-mobile {
  text-align: center;
}

.navbar-mobile ul li a {
  width: 100% !important;
  text-align: center !important;
}

.btn-profile {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
</style>

