<template>
    <div>
      <div class="dropdown_passenger_area">
        <div class="dropdown">
          <button
            class="dropdown-toggle final-count"
            data-toggle="dropdown"
            type="button"
            style="font-size: 1rem;"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ number }} hospedes <i style="font-size: .9rem !important" class="fas fa-pencil-alt"></i>
          </button>
  
          <div
            class="dropdown-menu dropdown_passenger_info"
            aria-labelledby="dropdownMenuButton1"
          >
            <div class="traveller-calulate-persons">
              <div class="passengers">
                <h6>Pessoas</h6>
                <div class="passengers-types">
                  <div class="passengers-type">
                    <div class="text">
                      <span class="count pcount">{{ anumber }}</span>
                      <div class="type-label">
                        <p>Adulto</p>
                        <span>18+ anos</span>
                      </div>
                    </div>
                    <div class="button-set">
                      <button
                        type="button"
                        class="btn-add"
                        @click.stop="increment"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn-subtract"
                        @click.stop="decrement"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="passengers-type">
                    <div class="text">
                      <span class="count ccount">{{ cnumber }}</span>
                      <div class="type-label">
                        <p class="fz14 mb-xs-0">Criança</p>
                        <span>Entre 2 - 6 anos</span>
                      </div>
                    </div>
                    <div class="button-set">
                      <button
                        type="button"
                        class="btn-add-c"
                        @click.stop="incrementc"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn-subtract-c"
                        @click.stop="decrementc"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="passengers-type">
                    <div class="text">
                      <span class="count incount">{{ bnumber }}</span>
                      <div class="type-label">
                        <p class="fz14 mb-xs-0">Bebe</p>
                        <span>Até 2 anos</span>
                      </div>
                    </div>
                    <div class="button-set">
                      <button
                        type="button"
                        class="btn-add-in"
                        @click.stop="incrementi"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn-subtract-in"
                        @click.stop="decrementi"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script setup>
import { hotelsStore } from '@/store/hotels.store';
import { storeToRefs } from 'pinia';

const { number, anumber, cnumber, bnumber, hotel } = storeToRefs(hotelsStore());

const increment = () => {
        number.value++;
        anumber.value++;
      }
  
      const  decrement = () => {
        if (number.value === 0) return;
        number.value -= 1;
  
        if (anumber.value === 0) return;
  
        anumber.value -= 1;
      }
  
      const    incrementc = () => {
        number.value++;
        cnumber.value++;
      }
  
      const    decrementc = () => {
        if (number.value === 0) return;
        number.value -= 1;
  
        if (cnumber.value === 0) return;
  
        cnumber.value -= 1;
      }
  
      const   incrementi = () => {
        number.value++;
        bnumber.value++;
      }
  
      const    decrementi = () => {
        if (number.value === 0) return;
        number.value -= 1;
  
        if (bnumber.value === 0) return;
  
        bnumber.value -= 1;
      }
  </script>