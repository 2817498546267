import { defineStore } from "pinia";
import { ref, reactive } from "vue";

export const hotelsStore = defineStore("HotelsStore", () => {
  const hotels = ref(null);
  const hotel = ref(null);
  const hotelsSearch = ref(null);
  const number = ref(0);
  const anumber = ref(0);
  const cnumber = ref(0);
  const bnumber = ref(0);

  const reservationRequest = ref(null);

  return {
    number,
    anumber,
    cnumber,
    bnumber,
    hotel,
    hotels,
    hotelsSearch,
    reservationRequest,
  };
});
