<template>
    <section id="vendor_form_area" class="section_padding_bottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="vendor_form_heading text-left">
              <h2>{{ title }}</h2>
              <p>{{ subTitle }}</p>
            </div>
          </div>
          <div class="col-md-6" v-if="!validy_title">
            <div>
              <div>
                <form action="!#" id="tour_bookking_form_item">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="form-group">
                        <label class="h4 text-primary">
                            Digite seu Cupom
                        </label>
                        <input
                          v-model="t"
                          type="text"
                          class="form-control bg_input"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="booking_tour_form_submit">
                <button
                  class="btn btn-primary"
                  :disabled="!t"
                  @click="getTitle(t)"
                >
                  Verificar cupom
                </button>

                <span class="text-danger d-block mt-2" v-if="msgTitleError">
                    Cupom não existe ou validade vencida
                </span>
              </div>
            </div>
          </div>
          <div v-else class="col-md-12">
            <h3 class="py-2 text-primary fw-bold">
                    Código válido:
                    {{ titleData?.plan }}
                    <span
                        v-if="titleData?.plan"
                        :class="titleData?.plan"
                        class="medal-r prata"
                        >
                        <i class="fas fa-medal" style="font-size: 1.5rem"></i>
                    </span>
                </h3>
            <div class="vendor_form">
              <div class="tour_booking_form_box">
                <form action="!#" id="tour_bookking_form_item">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="titleData.plan_indentify"
                          type="text"
                          :disabled="true"
                          class="form-control bg_input"
                          placeholder="plano"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          value="Convidado"
                          :disabled="true"
                          type="text"
                          class="form-control bg_input"
                          placeholder="convite"
                        />
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.name"
                          type="text"
                          class="form-control bg_input"
                          placeholder="Nome Completo*"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.phone"
                          type="text"
                          class="form-control bg_input"
                          placeholder="Celular*"
                          id="phone"
                          v-maska
                          data-maska="## # ####-####"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.email"
                          type="email"
                          class="form-control bg_input"
                          placeholder="E-mail"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.password"
                          type="password"
                          class="form-control bg_input"
                          placeholder="Senha*"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.confirmPassword"
                          type="password"
                          class="form-control bg_input"
                          placeholder="Confirmar senha*"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <select
                          v-model="dataRegister.contactPreference"
                          class="form-control bg_input form-select"
                          aria-label="Preferência de contato?"
                        >
                          <option value="nenhum" selected>Preferência de contato?</option>
                          <option value="Telefone">Pelo Telefone</option>
                          <option value="Whatsapp">Pelo Whatsapp</option>
                          <option value="E-mail">Pelo E-mail</option>
                        </select>
                      </div>
                    </div>

                    <h3 class="d-block mb-2">
                        Endereço
                    </h3>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.cep"
                          type="text"
                          class="form-control bg_input"
                          placeholder="CEP"
                          v-maska
                          data-maska="#####-###"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.cidade"
                          type="text"
                          class="form-control bg_input"
                          placeholder="Cidade"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.bairro"
                          type="text"
                          class="form-control bg_input"
                          placeholder="Bairro"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.logradouro"
                          type="text"
                          class="form-control bg_input"
                          placeholder="Logradouro"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.uf"
                          type="text"
                          class="form-control bg_input"
                          placeholder="UF"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          v-model="dataRegister.numero"
                          type="text"
                          class="form-control bg_input"
                          placeholder="Numero"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="booking_tour_form_submit pt-4">
                <div class="form-check write_spical_check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefaultf1"
                  />
                  <label class="form-check-label" for="flexCheckDefaultf1">
                    Eu li e aceito os
                    <router-link to="/"> termos de condições </router-link> e
                    <router-link to="/">politica de privacidade</router-link>
                  </label>
                </div>
                <button
                  class="btn btn-primary my-4"
                  @click="registerUser()"
                  :disabled="!validation()"
                >
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script setup>
  import { reactive, ref } from "vue";
  import { useRouter } from "vue-router";
  import { DB_UPDATE_PROFILE, Timestamp, CREATE_USER, GET_INVITE } from "@/root/firebase";
  import { notify } from "@kyvg/vue3-notification";
  import { vMaska } from "maska";
  
  const router = useRouter();
  const titleData = ref(null);
  const msgTitleError = ref(false);
  const t = ref('');
  const validy_title = ref(false);
  const dataRegister = reactive({
    plan_indentify: '',
    plan: '',
    plan_valid: true,
    plan_unlocked: true,
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    city: "",
    contactPreference: "nenhum",

    cep: '',
    cidade: '',
    logradouro: '',
    bairro: '',
    uf: '',
    numero: '',
  });
  
  const title = "Tenho um convite";
  const subTitle = `Cadastre seu cupom e reserve ainda hoje seu imóvel!`;


  const getTitle = () => {

    function isDateBeforeToday(validity_date) {
  // Obtém a data atual
    const today = new Date();

    // Compara as datas
    if (validity_date instanceof Date) {
        // Se a data de validade é anterior à data atual, retorna true
        return validity_date < today;
    } else {
        // Se validity_date não for uma instância de Date, retorna false (ou lança uma exceção, dependendo dos requisitos)
        // Validar data
        return true;
    }
    }

    GET_INVITE(t.value).then(_title => {
       titleData.value = _title.data();
      
       if (!isDateBeforeToday(titleData.value?.validy_date)) {
         msgTitleError.value = true;
         return;
       }
       if (titleData.value?.validy) validy_title.value = true;
       else msgTitleError.value = true;
    })
  }
  
  const validation = () => {
    if (
      dataRegister.name &&
      dataRegister.email &&
      dataRegister.phone &&
      dataRegister.password &&
      dataRegister.confirmPassword &&
      dataRegister.contactPreference
    )
      return true;
  
    return false;
  };
  
  const registerUser = () => {
    if (dataRegister.password !== dataRegister.confirmPassword) {
      return notify({
        title: "Error na senha",
        text: "Senhas não corresponde",
        type: "error",
      });
    }
    CREATE_USER(dataRegister.email, dataRegister.password)
      .then(async (user) => {
        delete dataRegister.confirmPassword;
  
        dataRegister.date_created = Timestamp.fromDate(new Date());
        dataRegister.indentify = user.uid;
        dataRegister.dep = false;
        dataRegister.plan = titleData.value?.plan;
        dataRegister.plan_indentify = titleData.value?.plan_indentify;
        dataRegister.plan_valid = titleData.value?.validy;
        dataRegister.password = dataRegister.password;
        dataRegister.cupom = true;

        await DB_UPDATE_PROFILE(user.uid, dataRegister);
        localStorage.setItem('token', user.accessToken);
  
        router.push({name: 'profile'});
      })
      .catch((error) => {
        console.error("Erro ao criar usuário:", error);
        notify({
          title: "Error ao cadastrar",
          text: error,
          type: "error",
        });
      });
  };
  </script>