<template>
  <section id="theme_search_form_tour">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="theme_search_form_area">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="tour_search_form">
                                    <form @submit.prevent>
                                        <div class="row justify-content-between">
                                            <div class="col-lg-10 col-md-10 col-sm-12 col-12">
                                                <div class="flight_Search_boxed">
                                                    <p>Local</p>
                                                    <input type="text" v-model="local" placeholder="Qual cidade você prefere?">
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                <div class="form_search_date">
                                                    <div class="flight_Search_boxed date_flex_area">
                                                        <div class="Journey_date">
                                                            <p>Check In</p>
                                                            <input type="date" value="">
                                                        </div>
                                                        <div class="Journey_date">
                                                            <p>Check Out</p>
                                                            <input type="date" value="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           <CountDropdownHotel /> -->
                                           <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                                                <div class="form_search_date">
                                                    <div>
                                                    <div class="d-grid gap-2">
                                                        <button @click="$router.push({name: 'tour-search', query: { search: local }})" type="button" style="height: 78px; border-radius: 12px" class="btn btn-primary btn-lg">
                                                            Buscar
                                                        </button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="top_form_search_button">
                                                <button class="btn btn_theme btn_md" type="button">Search</button>
                                            </div> -->
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import CountDropdownHotel from '@/components/hotel/CountDropdownHotel.vue'

export default {
   name: "Form",
   data() {
    return {
        local: ''
    }
   },
   components: {
    CountDropdownHotel
    },
};
</script>

<style scoped>
.Journey_date {
    max-width: 150px;
    cursor: pointer;
}

.nowrap {
    flex-wrap: nowrap;
}
</style>