<template>
  <section id="dashboard_main_arae" class="section_padding section_padding--mini">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="dashboard_common_table">
            <h3>Regulamento para Reservas</h3>
            <p>
                <div class="space"></div>            
1 Forneça o nº do seu titulo, local desejado e nome do titular e telefone.
<div class="space"></div>
2 Número de pessoas, casais, filhos, data da entrada e saída, fornecendo 3 opções de locais diferentes, de sua preferência efetuando depósito caução no máximo 24 horas úteis ( horário bancário).
<div class="space"></div>
3 As reservas para a temporada de verão podem ser efetuadas a partir de cada ano, e sempre será disponibilizado informativo
<div class="space"></div>
4 Reservas para pacote de Natal, Reveillon e Carnaval serão aceitas somente via telefone ou site quando disponível mediante vagas.
<div class="space"></div>
5 Para planos de 07, 14 e 21 dias (alta temporada) libera-se mais de 60 dias em média e baixa temporada, com taxas de uso promocional, ofertados pelo Departamento de Reservas Clube Kaboo TURISMO, estando em dia com as suas obrigações.
<div class="space"></div>
6 Planos de 28 e 35 ( alta temporada) libera-se o uso para a média e baixa temporada com valores promocionais consultando o Departamento de Reservas.
<div class="space"></div>
7 Residenciais e Hotéis constantes na REVISTA Clube Kaboo TURISMO são considerados preferenciais e atendem com taxas de acordo com as cláusulas da proposta de admissão do associado itens (9, 10 e 11).
<div class="space"></div>
8 PLANO 1- Em residenciais com cozinha, para a família, são para uso diário máximo de 04 e no máximo de 8 pessoas em um só apart. Item 13 da proposta de admissão, adequando-se ao residencial e ao número de pessoas que este opera, sempre no ato da reserva.

a) Pessoas excedentes não terão acesso ao apartamento, sob pena de multa no valor de R$300,00 (trezentos reais) por dia.
<div class="space"></div>
9 PLANO 2- Em hotéis com café da manha, o pernoite pode ser single(01 pessoa) double (02 pessoas), ou triple ( 03 pessoas), item 10 da proposta de admissão.
<div class="space"></div>
10 Hospedagens de hotéis com café da manhã, conta-se pernoites que seja inicio 14 (quatorze) horas, e fim as 12(doze)horas do dia seguinte.
<div class="space"></div>
11 Hospedagens em residenciais com cozinha conta- se o dia, que seja entrada as 08:30 h, até 20:00 h e saída até 19:00 da noite do último dia reservado. Tendo a possibilidade de fazer seu próprio café da manhã, almoço e jantar, dentro do período reservado.
<div class="space"></div>
12 Hospedagens podem ser em residenciais divididos em 07, 14, 21, 28 e 35 dias, podendo utilizar mais de 01(um) residencial de acordo com o plano ex: Alta temporada, plano de 14 dias: 07 (sete)dias em Torres/RS e 07(sete) dias em Itapema/SC ou 14 (quatorze) dias corridos em 01 (um ) só residencial.
<div class="space"></div>
13 O horário de entrada e saída em residenciais e hotéis, deve ser verificado no ato da reserva e respeitado no local da hospedagem, em geral os contratos Clube Kaboo TURISMO seguem os itens 10 e 11 deste regulamento.
<div class="space"></div>
14 As reservas serão confirmadas com depósito caução, se não utilizados comunique-se com a Clube Kaboo TURISMO, e veja a possibilidade de reverter-se em crédito, desde que comunicado com antecedência. Após confirmação dos depósitos de reservas o associado poderá desistir e cancelar desde que dê ciência (através do e-mail) ao clube, no prazo de até 10 ( dez) dias antes da entrada na unidade.
a) Ocorrendo dentro do prazo caput deste artigo, terá um crédito total do valor das diárias pagas.
b) Deverá estes créditos serem utilizados impreterivelmente dentro do prazo de 12 meses, contado a partir do dia do cancelamento, não sendo possível a prorrogação.
c) Cancelamento por caso extremos fora do prazo de 10 (dez) dias devidamente comprovado sendo estas: doenças, falecimentos, declarações judiciais, boletim de ocorrência, catástrofes naturais. O associado deverá entrar em contato até 01 (um) dia antes da sua entrada no apto, onde será cobrado 01 (uma) diária (no show) e o restante revertido em crédito.
<div class="space"></div>
15 As reservas para hotéis e residenciais que incluam crianças, com relação as taxas, deverão ser solicitado ao departamento de reservas, pois existem tabelas diversas de hotel para hotel e residencial para residencial, devendo sempre, o responsável pela criança, levar certidão de nascimento da mesma.
a) O titular poderá cadastrar até 04 (quatro) dependentes diretos, sendo pais, filhos e irmãos.
b) O titular poderá excluir dependentes diretos a qualquer tempo, porém somente poderá substituir cada dependente direto por outro dependente direito do titular.
c) Em caso de falecimento do titular por ser titulo vitalício, fica o mesmo resolvido entre as partes podendo os herdeiros ou sucessores nomearem um novo titular, mediante nova contratação e indicação de depende das normas estabelecidas.
<div class="space"></div>
16 O titular ou dependente direto poderá levar acompanhantes e não sócios pagando as mesmas taxas, desde que permaneça junto durante todo o período da hospedagem contratada do apartamento com cozinha ou hotel, não havendo nenhum tipo de negociação da Clube Kaboo TURISMO com seus convidados pois esses são de exclusividade vínculo seu, e não da ASSOCIAÇÃO.
<div class="space"></div>
17 Solicite no ato da reserva, se o local solicitado permite ou não a permanência de animais domésticos. Em caso de dificuldade com escadas em termos de locomoção interna e externa, verifique o local mais adequado.
<div class="space"></div>
18 A Clube Kaboo TURISMO não se responsabiliza por perdas ou danos que o associado venha a ter na unidade que estiver hospedado, devendo responsabilizar-se integralmente e obedecer as regras da boa convivência.
<div class="space"></div>
19 Os planos Clube Kaboo TURISMO são de utilização imediata, podendo a associação, acrescentar, substituir ou excluir residenciais, hotéis, centros de lazer ou gastronomia. De acordo com o bom andamento do sistema e regras acordadas.
<div class="space"></div>
20 O associado é usuário de plano e assim permanece por tempo vitalício. Porém não é obrigado a manter-se associado, bastando enviar correspondência solicitando o seu desligamento é aceito de imediato, porém não há devolução de valores pagos pelo seu ingresso no plano, sendo facultado ao associado transferir o título a terceiros nos termos do estatuto social ou contrato de admissão do associado.
<div class="space"></div>
21 As reservas deverão ser feitas pela Clube Kaboo TURISMO, de acordo com o item 5 da proposta de admissão do associado, lembrando que as reservas para Natal, Revellon e Carnaval, serão aceitas somente via telefone.
<div class="space"></div>
22 Quando a reserva se tratar de hospedagem em unidades mobiliadas com cozinha consulte a necessidade de se levar roupas de cama, mesa e banho, visto que diversas unidades não dispõem das mesmas, deverá levar também os recibos de pagamento total das taxas de reservas, pois não há recebimento de valores nestes locais.
<div class="space"></div>
23 Seguindo o item 1 e 2 deste regulamento as reservas podem ser por: telefone, site quando disponível ou pessoalmente, desde que obedeçam as unidades que se hospedar.
<div class="space"></div>
24 Ao longo do ano a Clube Kaboo TURISMO opera com valores promocionais de 04 (quatro) a 08 (oito) pessoas, consulte valores e períodos. No interesse maior da sociedade o presente REGULAMENTO geral das reservas poderá a qualquer tempo ser reformulado total ou parcialmente pela diretoria sem que as normas revogadas resultem em direito adquirido pelo associado.
<div class="space"></div><div class="space"></div>
25 Caso ou missões neste regulamento serão solucionados pela diretoria da Clube Kaboo TURISMO. Declaramos que unidades poderão ser retiradas ou acrescidas de acordo com o bom andamento do sistema ou força maior. Assim como o número (0800) que é um bônus ao associado conforme amplamente divulgada somente para reservas em residenciais com cozinha sendo o telefone (48 3641 5400) como número oficial e permanente, ligada a Central de Reservas.
Agora que você já conhece nosso regulamento de reservas
Faça sua Busca e boa Viagem!
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import LogoutBtn from "@/components/dashboard/LogoutBtn.vue";
import MyBookingOption from "@/components/dashboard/MyBookingOption.vue";
export default {
  name: "HotelBookingDashboard",
  components: {
    LogoutBtn,
    MyBookingOption,
  },
};
</script>
  
  <style scoped>
tr,
th {
  text-align: left;
}
td {
  font-size: 0.9rem !important;
  text-align: left;
}

.space {
  padding: 0.5rem 0px;
}
</style>