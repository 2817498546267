<template>

  <!-- Form Area -->
  <Form />

  <!-- Explore our hot deals -->
  <!-- <ExploreDeals /> -->

  <!-- Offer Area -->
  <!-- <Offer /> -->

  <div class="container">
  <div class="row align-items-center row1">
    <div class="col-md-4 box mb-4 mb-md-0">
      <div class="d-flex align-items-center">
        <i class="fas fa-unlock-alt ic1"></i>
        <h3>
          Desbloquear
          <small>
            Cadastro
          </small>
        </h3>
      </div>
      
      <div class="d-grid gap-2 col-8 mx-auto">
        <p class="py-2">
          Preencha o formulário e reserve ainda hoje seu imóvel!
        </p>
        <button class="btn btn-primary" :disabled="account" type="button" @click="$router.push({name: 'unlock'})">Desbloquear meu cadastro</button>
      </div>
    </div>
    <div class="col-md-4 box mb-4 mb-md-0">
      <div class="d-flex align-items-center">
        <i class="fas fa-ticket-alt ic1"></i>
        <h3>
          Possui um
          <small>
            Convite?
          </small>
        </h3>
      </div>
      
      <div class="d-grid gap-2 col-7 mx-auto">
        <p class="py-2">
          Você colocará o código do convite após fazer o seu cadastro.
        </p>
        <button :disabled="account" @click="$router.push({name: 'invite'})" class="btn btn-primary" type="button">Usar convite</button>
      </div>
    </div>

    <div class="col-md-4 box mb-4 mb-md-0">
      <div class="d-flex align-items-center">
        <i class="fas fa-medal ic1"></i>
        <h3>
          <small>
            Conheça nossos
          </small>
          Títulos
        </h3>
      </div>
      
      <div class="d-grid gap-2 col-7 mx-auto">
        <p class="py-2">
          Ainda não é um Associado Kaboo?
          Conheça as vantagens!
        </p>
        <button class="btn btn-primary" type="button">Conhecer</button>
      </div>
    </div>
  </div>
  </div>

<!-- <div class="container mt-4">
  <div class="row justify-content-between align-items-center row1">
    <div class="col-md-12">
        <h3>
          Destinos com até 40% de desconto!!!
        </h3>
    </div>
    <div class="col-md-12 mt-2">
      <div class="swiper mySwiper2">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="locale">
            <img src="@/assets/local/litoral-paulista.jpg" />
            <div class="title">
              <h4>
                Litoral Paulista
              </h4>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="locale">
            <img src="@/assets/local/rio-de-janeiro.jpg" />
            <div class="title">
              <h4>
                Rio de Janeiro
              </h4>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="locale">
            <img src="@/assets/local/santa-catarina.jpg" />
            <div class="title">
              <h4>
                Santa Catarina
              </h4>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="locale">
            <img src="@/assets/local/nordeste.png" />
            <div class="title">
              <h4>
                Nordeste
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div> -->

<div class="container mt-4">
  <div class="row justify-content-between align-items-center row1">
    <div class="col-md-12">
        <h3>
          Residências casas e apartamentos em destaque
        </h3>
    </div>
    <div class="col-md-12 mt-2">
      <div class="swiper mySwiper3">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
          v-for="hotel in housesAndApartments"
          :key="hotel?.id"
          @click="$router.push({name: 'hotel-details', params: { indentify: hotel?.id }})"
        >
          <div class="locale">
            <div class="locale-capa">
              <img :src="hotel?.photos[0]" />
            </div>
            <div class="title">
              <h4>
                <i class="fas fa-star text-warning"></i>
                 {{ hotel?.name }}
              </h4>
              <span class="text-muted">
                <i class="fas fa-map-marker-alt"></i>
                {{ hotel?.city}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
    </div>
  </div>
</div>

<div class="container mt-4">
  <div class="row justify-content-between align-items-center row1">
    <div class="col-md-12">
        <h3>
          Hotéis e pousadas
        </h3>
    </div>
    <div class="col-md-12 mt-3">
      <div class="swiper mySwiper4">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
          v-for="hotel in hotelAndGuesthouses"
          :key="hotel?.id"
          @click="$router.push({name: 'hotel-details', params: { indentify: hotel?.id }})"
        >
          <div class="locale">
            <div class="locale-capa">
              <img :src="hotel?.photos[0]" />
            </div>
            <div class="title">
              <h4>
                 {{ hotel?.name }}
              </h4>
              <span class="text-muted">
                 <i class="fas fa-map-marker-alt"></i>
                  {{ hotel?.city}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>

<div class="container mt-4">
  <div class="row justify-content-between align-items-center row1">
    <div class="col-md-12">
        <h3>
          Parques e clubes
        </h3>
    </div>
    <div class="col-md-12 mt-3">
      <div class="swiper mySwiper4">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
          v-for="hotel in hotelParkAndClub"
          :key="hotel?.id"
          @click="$router.push({name: 'hotel-details', params: { indentify: hotel?.id }})"
        >
          <div class="locale">
            <div class="locale-capa">
              <img :src="hotel?.photos[0]" />
            </div>
            <div class="title">
              <h4>
                 {{ hotel?.name }}
              </h4>
              <span class="text-muted">
                 <i class="fas fa-map-marker-alt"></i>
                  {{ hotel?.city}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>

<div class="container mt-4">
  <div class="row justify-content-between align-items-center row1">
    <div class="col-md-12">
        <h3>
          Cupom com descontos
        </h3>
    </div>
    <div class="col-md-12 mt-2">
      <div class="swiper mySwiper5">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="cupom in cupons"
          :key="cupom?.indentify"
          @click.prevent="generateLink(cupom)"
        >
          <div class="locale">
            <div class="locale-capa">
              <img :src="cupom?.photos[0]" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
  <!-- Cta Area -->
  <Cta style="margin-top: 5rem" />

</template>

<script setup>
import { ref } from "vue";
import { onMounted } from "vue";
import Form from '@/components/hotel/Form.vue'
import Cta from '@/components/home/Cta.vue'
import { storeToRefs } from "pinia";
import { accountStore } from "@/store/account.store";
import { getHotelsByCategories, getCuppons } from "@/root/firebase";

const { account } = storeToRefs(accountStore());

const housesAndApartments = ref([])
const hotelAndGuesthouses = ref([])
const hotelParkAndClub = ref([])
const cupons = ref([])

onMounted(() => {
  var swiper = new Swiper(".mySwiper2", {
    slidesPerView: 1,
    spaceBetween: 20,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    }
  });

  const swiper3 = new Swiper(".mySwiper3", {
    slidesPerView: 2,
    spaceBetween: 10,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
  });

  const swiper4 = new Swiper(".mySwiper4", {
    slidesPerView: 2,
    spaceBetween: 10,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
  });

  const swiper5 = new Swiper(".mySwiper5", {
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      },
  });
});

const getHousesAndApartments = async () => {
  try {
    housesAndApartments.value = await getHotelsByCategories(['Casa', 'Apartamento']);
    console.log('housesAndApartments.value', housesAndApartments.value)
  } catch (err) {
    console.error(err);
  }
}

const getHotelAndGuesthouses = async () => {
  try {
    hotelAndGuesthouses.value = await getHotelsByCategories(['Hotel', 'Pousada']);
  } catch (err) {
    console.error(err);
  }
}

const getHotelParkAndClub = async () => {
  try {
    hotelParkAndClub.value = await getHotelsByCategories(['Parque', 'Clube']);
  } catch (err) {
    console.error(err);
  }
}

const getCuponsAll = async () => {
  try {
    cupons.value = await getCuppons();
  } catch (err) {
    console.error(err);
  }
}

const generateLink = (cupom) => {
  const title = cupom?.name || '';
  const drescription = `Gostaria de saber sobre o cupom: ${title}`;
  
  // Função para gerar a URL de mensagem do WhatsApp
    const numeroTelefone = "554132253647"; // Número do WhatsApp com DDI e DDD
    const msg = `${drescription}`;
    const msgCod = encodeURIComponent(msg); // Codifica para a URL
    const link = cupom?.campBannerLink || `https://wa.me/${numeroTelefone}?text=${msgCod}`;

    window.open(link, "_blank");
}

getHousesAndApartments();
getHotelAndGuesthouses();
getHotelParkAndClub();
getCuponsAll();
</script>

<style scoped>
.row1 {
  margin-top: 5rem;
}

.ic1 {
  font-size: 3rem;
  margin-right: 1rem;
}

.row2 {
  margin: 9rem 0px
}

h1 {
  font-size: 3.2rem !important;
  line-height: 3.5rem !important;
}

h3 {
  font-size: 2rem !important;
  font-weight: bold;
}

h3 small {
  display: block;
  font-weight: 400;
}

h4 {
  font-size: 1rem;
}

p {
  line-height: 16px;
  font-size: 14px;
}

.box:nth-child(1) {
  border-left: none;
}

.box {
  border-left: 1px solid rgba(0,0,0,0.1);
  padding: 0px 0rem 0px 2rem;
}

@media screen and (max-width: 900px) {
  .row1 {
    margin-top: 2rem;
  }

  .row2 {
    margin: 3rem 0px
  }

  h1 {
  font-size: 2.2rem !important;
  line-height: 2.5rem !important;
}

h3 {
  font-size: 1.5rem !important; 
}
}

.locale {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  overflow: hidden
}

.locale .locale-capa {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
}

.locale .locale-capa img {
  width: 100%;
}

.locale .title {
  padding: 1rem;
}

.locale .title span {
  font-size: .9rem;
}

.swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0px;
      cursor: pointer;
    }

    .swiper-slide:hover img {
      opacity: .8;
    }

    .swiper-slide:hover h4 {
      color: var(--main-color);
    }

    .porcente {
      width: 3rem;
      height: 3rem;
      border-radius: 100px;
      background: var(--main-color);
      color: #fff;
      font-weight: bold;
      font-size: 1rem;
      position: absolute;
      top: .2rem;
      right: .2rem;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 5px #79eef7;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: auto;
      transition: all .4s ease-in-out;
    }

    @media all and (max-width: 700px) {
      h3 {
        text-align: center;
        font-size: 1.2rem;
      }
      .locale .title h4{
        font-size: 1rem;
      }
      .locale .locale-capa {
        width: 100% !important;
        height: 120px;
        overflow: hidden;
      }
    }
</style>