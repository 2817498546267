<template>
  <section
    id="dashboard_main_arae"
    class="section_padding section_padding--mini"
  >
    <div class="container" v-if="account?.plan_indentify && !account?.dep && account?.plan_unlocked">
      <div class="row justify-content-between">
        <div class="col-md-12">
          <div class="dashboard_common_table">
            <h3>
              Dependentes
        
            <button type="button" class="btn btn-primary float-end" style="margin-top: -.5rem" @click="getDependent(null)" data-bs-toggle="modal" data-bs-target="#addDependent">
              Cadastrar dependente
            </button>
   
            </h3>
            <div v-if="listDependents.length">
              <ul class="border-b" v-for="dependent in listDependents" :key="dependent.indentify">
                <li>
                  <div>
                    <h4 class="mb-3 mt-4">
                      {{ dependent.name }}
                    </h4>
                    <div class="row" style="width: 100%">
                      <div class="info col-md-2">
                        <div class="text-secondary">
                          Status de acesso
                        </div>
                        <span class="text-success">
                          Ativo
                        </span>
                      </div>
                      <div class="info col-md-2">
                        <div  class="text-secondary">
                          Data do contrado
                        </div>
                        <span class="text-dark">
                          {{
                            dependent.date_created.seconds ? 
                            new Date(dependent.date_created.seconds*1000)
                            .toLocaleDateString('pt-BR')
                            : new Date(dependent.date_created).toLocaleDateString('pt-BR')
                           }}
                        </span>
                      </div>
                      <div class="info col-md-2 mb-3">
                        <div  class="text-secondary">
                          CPF
                        </div>
                        <span class="text-dark">
                          {{ dependent.cpf }}
                        </span>
                      </div>
                      <div class="info col-md-2 mb-3">
                        <div class="text-secondary">
                          Parentesco
                        </div>
                        <span class="text-dark">
                          {{ dependent.kinship }}
                        </span>
                      </div>
                      <div class="info col-md-2 mb-3">
                        <div class="text-secondary">
                          Sexo
                        </div>
                        <span class="text-dark">
                          {{ dependent.sex }}
                        </span>
                      </div>
                      <div class="info col-md-2 mb-3">
                        <div class="text-secondary">
                          Nascimento
                        </div>
                        <span class="text-dark">
                          {{ dependent.date_birth }}
                        </span>
                      </div>
                      <div class="info col-md-2 mb-3">
                        <div class="text-secondary">
                          Celular
                        </div>
                        <span class="text-dark">
                          {{ dependent.phone }}
                        </span>
                      </div>
                      <div class="info col-md-2 mb-3">
                        <div class="text-secondary">
                          E-mail
                        </div>
                        <span class="text-dark">
                          {{ dependent.email }}
                        </span>
                      </div>
                      <div class="info col-md-2 mb-3">
                        <div class="text-secondary">
                          Senha de acesso
                        </div>
                        <span class="text-dark">
                          {{ dependent.password }}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#addDependent"
                    @click="getDependent(dependent)"
                  >
                    Editar Dependente
                  </button>
                </li>
              </ul>
            </div>
            <span class="mt-3 d-block font-monospace text-secondary" v-else>
              Nenhum dependente foi encontrado...
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" ref="modalRef" id="addDependent" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cadastrar Dependente</h5>
          </div>
          <AddDependent @closed="closed()" :dependent="dependent" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
import AddDependent from './AddDependent.vue';
import { Modal } from 'bootstrap';
import { accountStore } from '@/store/account.store';
import { storeToRefs } from 'pinia';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from '@/root/firebase';

const { account, listDependents } = storeToRefs(accountStore());

const modalRef = ref(null);
const dependent = ref(null);

const getDependent = (dep) => {
  dependent.value = dep;
}

const closed = () => {
  dependent.value = null;
  Modal.getInstance(modalRef.value)?.hide();
}

const fetchDependents = async (force = false) => {
  if (listDependents.value?.length && !force) return;
  listDependents.value = [];
  
  if (!account.value?.plan_indentify || account.value?.dep) return;

  const q = query(
    collection(db, "profiles"), 
    where("indentify", "!=", account.value.indentify),
    where("plan_indentify", "==", account.value.plan_indentify)
  );

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    listDependents.value.push(doc.data());
  });
}

onMounted(() => {
  fetchDependents();
})

watch(account, () => {
  fetchDependents(true)
})
</script>
    
<style scoped>
tr,
th {
  text-align: left;
}
td {
  font-size: 0.9rem !important;
  text-align: left;
}

.space {
  padding: 0.5rem 0px;
}

.border-b {
  border-bottom: 2px solid rgba(0,0,0, .1);
  padding: 0px 0px 1rem 0px;
}
</style>