import { defineStore } from "pinia";
import  { reactive, ref, computed } from "vue";
import { notify } from "@kyvg/vue3-notification";

export const adminStore = defineStore("AdminStore", () => {
  const profiles = ref(null);

  return {
    profiles
  };
});
