import { defineStore } from "pinia";
import  { ref, computed } from "vue";

export const accountStore = defineStore("AccountStore", () => {
  const account = ref(null);
  const listDependents = ref([]);
  const isLogout = ref(false);
  const userIsAuthenticated = window.localStorage.getItem('token');

  const setAccount = (_account) => {
    account.value = _account;
  }

  const getDependentsList = computed(() => {
   return JSON.parse(localStorage.getItem('dependentsList')) || [];
  })
  

  return {
    account,
    isLogout,
    listDependents,
    userIsAuthenticated,
    getDependentsList,
    setAccount
  };
});
