<template>
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="tou_booking_form_Wrapper">
                        <div class="tour_booking_form_box mb-4">
                            <div class="booking_success_arae">
                                <div class="booking_success_img">
                                    <img src="../../assets/img/icon/right.png" alt="img">
                                </div>
                                <div class="booking_success_text">
                                    <h3>{{ account.name }}, seu pedido foi enviado com sucesso!</h3>
                                    <h6>Os detalhes da sua reserva foram enviados com sucesso, aguarde até a confirmação da reserva com a nossa equipe.</h6>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="booking_tour_form">
                            <h3 class="heading_theme">Sua informação</h3>
                            <div class="tour_booking_form_box">
                                <div class="your_info_arae">
                                    <ul>
                                        <li><span class="name_first">Primeiro nome:</span> <span
                                                class="last_name">Diego</span></li>
                                        <li><span class="name_first">Sobrenome:</span> <span
                                                class="last_name">Alerico Pinto</span></li>
                                        <li><span class="name_first">E-mail:</span> <span
                                                class="last_name">destakrecarga@hotmail.com</span></li>
                                        <li><span class="name_first">Endereço:</span> <span class="last_name">1901
                                               Rua ... N° 400</span></li>
                                        <li><span class="name_first">Cidade:</span> <span
                                                class="last_name">Curitiba</span></li>
                                        <li><span class="name_first">UF:</span> <span class="last_name">PR</span>
                                        </li>
                                        <li><span class="name_first">CEP:</span> <span
                                                class="last_name">403432432</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="col-lg-4">
                    <div class="tour_details_right_sidebar_wrapper">
                        <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>Detalhes da reserva</h3>
                                </div>
                                <div class="tour_booking_amount_area">
                                    <ul>
                                        <li>Reserva ID: <span>#RB5783GH</span></li>
                                        <li>Data da reserva: <span> {{ new Date().toLocaleDateString('pt-BR') }}</span></li>
                                        <li>Forma de pagamento: <span> PIX </span></li>
                                        <li>Status da reserva: <span> Pendente</span></li>
                                    </ul>
                                    <ul>
                                        <li>Preço adulto x 1 <span>R$800,00 </span></li>
                                        <li class="remove_coupon_tour">Desconto <span>10%</span></li>
                                        <li>Taxa <span>5%</span></li>
                                    </ul>
                                    <div class="tour_bokking_subtotal_area">
                                        <h6 class="remove_coupon_tour">Subtotal <span>R$740,00</span></h6>
                                    </div>
                                    <div class="coupon_add_area">
                                        <h6><span class="remove_coupon_tour">Remove</span> Cupom (OFF 5%)
                                            <span> R$40,00 </span>
                                        </h6>
                                    </div>
                                    <div class="total_subtotal_booking">
                                        <h6 class="remove_coupon_tour"> Valor total <span> R$700,00 </span> </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>

</template>
<script setup>
import { accountStore } from '@/store/account.store';
import { storeToRefs } from 'pinia';

const { account } = storeToRefs(accountStore());

</script>