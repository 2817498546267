<template>
  <div class="container modal-body">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="vendor_form">
          <div>
            <form
              @submit.prevent="registerHotel()"
              enctype="multipart/form-data"
            >
              <h4 class="mb-3">Upload de Imagens</h4>

              <div class="row g-2">
                <div
                  class="col-lg-6 box-file"
                  v-for="(item, index) in 15"
                  :key="item"
                >
                  <span
                    v-show="formData?.photos[index]"
                    @click="removeFile(index)"
                  >
                    <span class="btn btn-danger remove-file">
                      <i class="fas fa-times"></i>
                    </span>
                  </span>
                  <img
                    v-if="formData?.photos && formData?.photos[index]"
                    :src="formData.photos[index]"
                    class="thumbnail"
                  />
                  <input
                    type="file"
                    class="form-control"
                    @change="handleFileChange($event, index)"
                  />
                </div>

                <h4 class="mb-3">Informações</h4>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="f-name">Categoria</label>
                    <select
                      v-model="formData.category"
                      class="form-control form-select"
                    >
                      <option selected>Selecione</option>
                      <option value="Hotel">Hotel</option>
                      <option value="Pousada">Pousada</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group has-valindentifyation">
                    <label for="f-name">Link de campanha</label>
                    <input
                      autocomplete="off"
                      v-model="formData.campLink"
                      type="text"
                      class="form-control"
                      placeholder="Link de campanha"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group has-valindentifyation">
                    <label for="f-name">Nome</label>
                    <input
                      autocomplete="off"
                      v-model="formData.name"
                      type="text"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="f-name">Cidade</label>
                    <input
                      v-model="formData.city"
                      type="text"
                      class="form-control"
                      placeholder="Cidade"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="f-name">Bairro</label>
                    <input
                      v-model="formData.neighborhood"
                      type="text"
                      class="form-control"
                      placeholder="Bairro"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="mail-address">Visão Geral</label>
                    <VueEditor v-model="formData.about" />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="f-comodidade">Adicionar Comodidade</label>
                    <div class="flex d-flex">
                      <input
                        v-model="amenitie"
                        type="text"
                        class="form-control"
                        placeholder="Adicionar Comodidade"
                      />
                      <button
                        @click="addAmenitie(amenitie)"
                        class="btn btn-primary"
                        style="margin-left: 1rem"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-4"
                  v-for="amenitie in formData.amenities"
                  :key="amenitie"
                >
                  <div class="form-group">
                    <span>
                      <strong> {{ amenitie }} </strong>
                    </span>
                    <button
                      @click="removeAmenitie(amenitie)"
                      class="btn btn-primary"
                      style="margin-left: 1rem"
                    >
                      -
                    </button>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="f-name">O que levar</label>
                    <VueEditor v-model="formData.whatToTake" />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="f-name">Regras de acomodação</label>
                    <VueEditor v-model="formData.rules" />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="f-plano">Plano</label>
                    <select
                      v-model="formData.plan"
                      class="form-control form-select"
                    >
                      <option selected>Selecione</option>
                      <option value="ouro">Ouro</option>
                      <option value="prata">Prata</option>
                      <option value="bBronze">Bronze</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="f-name">Localização</label>
                    <input
                      v-model="formData.location"
                      type="text"
                      class="form-control"
                      indentify="f-name"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="Celular">N min de pessoas</label>
                    <input
                      v-model="formData.minPerson"
                      type="number"
                      class="form-control"
                      indentify="Pessoas"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="Celular">N max de pessoas</label>
                    <input
                      v-model="formData.person"
                      type="number"
                      class="form-control"
                      indentify="Pessoas"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="Celular">Preço</label>
                    <input v-model="formData.price" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="Celular">Preço para sócio</label>
                    <input
                      v-model="formData.member_price"
                      class="form-control"
                    />
                  </div>
                </div>

                <!-- Novos Campos para Intervalos de Data e Preço -->
                <div class="col-lg-12">
                  <h4 class="mb-3 mt-3">Intervalos de data e preço especial</h4>
                  <div class="mb-3">
                    <label for="date-range-start">Data de início</label>
                    <input
                      v-model="newDateRange.start"
                      type="date"
                      class="form-control"
                      id="date-range-start"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="date-range-end">Data de término</label>
                    <input
                      v-model="newDateRange.end"
                      type="date"
                      class="form-control"
                      id="date-range-end"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="special-price">Preço especial</label>
                    <input
                      v-model="newDateRange.price"
                      type="text"
                      class="form-control"
                      id="special-price"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="special-price">Preço especial para sócio</label>
                    <input
                      v-model="newDateRange.memberPrice"
                      type="text"
                      class="form-control"
                      id="special-price"
                    />
                  </div>
                  <button @click.prevent="addDateRange" class="btn btn-primary">
                    Adicionar Preço
                  </button>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="Celular">Mapa</label>
                    <input
                      v-model="formData.map"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-3 mt-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="formData.disabled"
                        id="flexCheckChecked"
                        checked
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Desabilitado
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  :disabled="loadingImage"
                  class="btn btn-primary"
                >
                  {{
                    loadingImage
                      ? "Carregando aguarde..."
                      : prop.dependent
                      ? "Editar Cadastro"
                      : "Inserir Cadastro"
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineEmits, watch, ref } from "vue";
import {
  Timestamp,
  DB_SET_HOTEL,
  storage,
  storageRef,
  uploadBytes,
  getDownloadURL,
  formatText,
} from "@/root/firebase";
import { notify } from "@kyvg/vue3-notification";
import { VueEditor } from "vue3-editor";

const emit = defineEmits(["closed", "setHotel"]);
const prop = defineProps(["dependent"]);
const amenitie = ref("");

const loadingImage = ref(false);

const formData = reactive({
  indentify: "",
  name: "",
  date_created: "",
  active: true,
  city: "",
  neighborhood: "",
  location: "",
  about: "",
  person: "",
  plan: "",
  price: "",
  member_price: "",
  high_season_price: "",
  map: "",
  photos: [],
  amenities: [],
  rules: "",
  minPerson: "",
  whatToTake: "",
  campLink: "",
  dateRanges: [],
});

watch(
  () => prop.dependent,
  (dependent) => {
    if (dependent) {
      formData["indentify"] = dependent.indentify;
      formData["name"] = dependent.name;
      formData["date_created"] = dependent.date_created;
      formData["active"] = dependent.active;
      formData["city"] = dependent.city;
      formData["campLink"] = dependent.campLink;
      formData["neighborhood"] = dependent.neighborhood;
      formData["location"] = dependent.location;
      formData["about"] = dependent.about;
      formData["person"] = dependent.person;
      formData["plan"] = dependent.plan;
      formData["price"] = dependent.price;
      formData["member_price"] = dependent.member_price;
      formData["high_season_price"] = dependent.high_season_price;
      formData["map"] = dependent.map;
      formData["photos"] = dependent.photos;
      formData["category"] = dependent.category ?? "";
      formData["disabled"] = dependent.disabled || false;
      formData["search"] = dependent.search || formatText(dependent.city);
      formData["amenities"] = dependent.amenities || [];
      formData["rules"] = dependent.rules;
      formData["minPerson"] = dependent.minPerson;
      formData["whatToTake"] = dependent.whatToTake;
      formData["dateRanges"] = dependent.dateRanges || [];
      //   for (let i = 0; i < 6; i++) {
      //     updateFile(i, dependent.photos[index])
      //   }
    } else {
      formData["indentify"] = "";
      formData["name"] = "";
      formData["date_created"] = "";
      formData["active"] = "";
      formData["city"] = "";
      formData["neighborhood"] = "";
      formData["location"] = "";
      formData["about"] = "";
      formData["person"] = "";
      formData["plan"] = "";
      formData["price"] = "";
      formData["member_price"] = "";
      formData["high_season_price"] = "";
      formData["map"] = "";
      formData["photos"] = [];
      formData["category"] = "";
      formData["disabled"] = false;
      formData["search"] = "";
      formData["amenities"] = [];
      formData["rules"] = "";
      formData["minPerson"] = "";
      formData["campLink"] = "";
      formData["whatToTake"] = "";
      formData["dateRanges"] = [];
    }
  }
);

const addDependent = () => {
  formData["indentify"] = "";
  formData["name"] = "";
  formData["date_created"] = "";
  formData["active"] = "";
  formData["city"] = "";
  formData["neighborhood"] = "";
  formData["location"] = "";
  formData["about"] = "";
  formData["person"] = "";
  formData["plan"] = "";
  formData["price"] = "";
  formData["member_price"] = "";
  formData["high_season_price"] = "";
  formData["map"] = "";
  formData["photos"] = [];
  formData["category"] = "";
  formData["disabled"] = false;
  formData["search"] = "";
  formData["amenities"] = [];
  formData["rules"] = "";
  formData["minPerson"] = "";
  formData["campLink"] = "";
  formData["whatToTake"] = "";
  formData["dateRanges"] = [];
  emit("closed");
};

const newDateRange = reactive({
  start: "",
  end: "",
  price: "",
});

const addDateRange = () => {
  if (
    newDateRange.start &&
    newDateRange.end &&
    newDateRange.price &&
    newDateRange.memberPrice
  ) {
    formData.dateRanges.push({ ...newDateRange });
    newDateRange.start = "";
    newDateRange.end = "";
    newDateRange.price = "";
    newDateRange.memberPrice = "";
  }
};

const removeDateRange = (index) => {
  formData.dateRanges.splice(index, 1);
};

const addAmenitie = (value) => {
  if (!value) return;
  if (!formData.amenities) formData.amenities = [];
  formData.amenities.push(value);
  amenitie.value = "";
};

const removeAmenitie = (value) => {
  formData.amenities = formData.amenities.filter((x) => x !== value);
};

const handleFileChange = (event, index) => {
  if (!event) return;
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = () => {
    submitphoto(reader.result, index);
  };
  reader.readAsDataURL(file);
};

const removeFile = (index) => {
  formData.photos[index] = null;
};

const dataURItoFile = (dataURI, filename) => {
  const arr = dataURI.split(",");
  if (!arr.length) return;
  let mime = arr[0].match(/:(.*?);/);
  if (!mime) return;
  mime = mime[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const submitphoto = async (image, index) => {
  if (image) {
    const file = dataURItoFile(image, `image_${Date.now()}.png`);
    if (!file) return;

    const storageRefs = storageRef(storage, `images/${file.name}`);

    if (!file) return;

    try {
      const snapshot = await uploadBytes(storageRefs, file);
      const url = await getDownloadURL(snapshot.ref);
      console.log("URL completa do arquivo carregado:", url);
      formData.photos[index] = url;
    } catch (error) {
      console.error("Erro ao carregar o arquivo:", error);
    }
  }
};

const submitphotos = async () => {
  loadingImage.value = true;

  for (const image of formData.photos) {
    let index = 0;
    if (image) {
      const file = dataURItoFile(image, `image_${Date.now()}.png`);
      if (!file) return;

      const storageRefs = storageRef(storage, `images/${file.name}`);

      if (!file) return;

      try {
        const snapshot = await uploadBytes(storageRefs, file);
        const url = await getDownloadURL(snapshot.ref);
        console.log("URL completa do arquivo carregado:", url);
        formData.photos[index] = url;
      } catch (error) {
        console.error("Erro ao carregar o arquivo:", error);
      }
    }
    index += 1;
  }
};

const parseCurrency = (value) => {
  if (typeof value !== "string") {
    return value;
  }

  // Remove pontos (milhares) e substitui vírgula por ponto
  value = value.replace(/\./g, "").replace(",", ".");

  return parseFloat(value);
};

const registerHotel = async () => {
  function generateRandomIdentifier(length = 20) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let identifier = "";
    for (let i = 0; i < length; i++) {
      identifier += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return identifier;
  }

  let identifier = generateRandomIdentifier();
  if (prop.dependent) identifier = prop.dependent["indentify"];

  formData["indentify"] = identifier;

  formData["price"] = parseCurrency(formData["price"]);
  formData["high_season_price"] = parseCurrency(formData["high_season_price"]);
  formData["member_price"] = parseCurrency(formData["member_price"]);
  formData["search"] = formatText(formData["city"]);

  const data = JSON.parse(JSON.stringify(formData));
  data.date_created = Timestamp.fromDate(new Date());
  data.guests = data.person;
  data.state = "";

  // await submitphotos()
  loadingImage.value = true;

  DB_SET_HOTEL(identifier, data)
    .then(async (hotel) => {
      emit("setHotel", data);
      addDependent();
    })
    .catch((error) => {
      console.error("Erro ao criar usuário:", error);
      notify({
        title: "Error ao cadastrar",
        text: "Não conseguimos salvar",
        type: "error",
        timeout: 3000,
      });
    })
    .finally(() => {
      loadingImage.value = false;
    });
};
</script>

<style scoped>
.box-file {
  position: relative;
}
.remove-file {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
</style>
