<template>
    <div style="padding-top: 12rem"></div>
    <!-- Form Area -->
    <Form />

    <!-- Tour Search Areas -->
   <ExploreDeals />

    <!-- Cta Area -->
    <Cta />
</template>
<script>
import TourBanner from '@/components/tour/TourBanner.vue'
import TourForm from '@/components/tour/TourForm.vue'
import TourSearch from '@/components/tour/TourSearch.vue'
import Cta from '@/components/home/Cta.vue'
import Form from '@/components/hotel/Form.vue'
import ExploreDeals from '@/components/home/ExploreDeals.vue'

export default {
    name: "TourSearchView",
    components: {
        TourBanner, TourForm, TourSearch, Cta, Form, ExploreDeals
    }
};
</script>
