<template>
  <section
    v-if="hotel"
    id="tour_details_main"
    class="section_padding"
    style="margin-top: -2.5rem"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="tour_details_leftside_wrapper">
            <div class="tour_details_heading_wrapper">
              <div class="tour_details_top_heading">
                <h2>{{ hotel.name }}</h2>
                <h5>
                  <i class="fas fa-map-marker-alt"></i> {{ hotel.city }}
                  {{ hotel.neighborhood }}  {{ hotel.state }}
                </h5>
              </div>
            </div>
            <div class="tour_details_img_wrapper" style="margin-top: 1rem">
              <div class="image__gallery">
                <div class="gallery">
                  <div class="gallery__slideshow">
                    <div class="gallery__slides">
                      <div class="gallery__slide d-flex justify-content-center">
                        <img
                          class="gallery__img"
                          :src="slide"
                          alt=""
                          v-for="(slide, index) in hotel.photos.filter(photo => photo)"
                          :key="index"
                          :style="
                            slideIndex === index
                              ? 'display:block;'
                              : 'display:none;'
                          "
                          style="height: 450px; width: auto"
                        />
                      </div>
                    </div>
                    <a @click.prevent="move(-1)" class="gallery__prev"
                      >&#10095;</a
                    >
                    <a @click.prevent="move(1)" class="gallery__next"
                      >&#10094;</a
                    >
                  </div>

                  <div class="gallery__content">
                    <div class="gallery__items">
                      <div
                        class="gallery__item"
                        :class="{
                          'gallery__item--is-acitve': slideIndex === index,
                        }"
                        v-for="(slide, index) in hotel.photos.filter(photo => photo)"
                        :key="`item-${index}`"
                      >
                        <img
                          :src="slide"
                          @click="currentSlide(index)"
                          style="height: 100px; width: 200px"
                          class="gallery__item-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tour_details_boxed">
              <h3 class="heading_theme">Visão geral</h3>
              <div class="tour_details_boxed_inner">
                <div v-html="hotel?.about"></div>
                <!-- <h4>
                  <strong> Principais comodidades </strong>
                </h4>
                <p class="d-flex flex-wrap justify-content-between gap-3 mt-3">
                  <span>
                    <i class="fas fa-users" style="margin-right: 0.3rem"></i>
                    Quartos para famílias
                  </span>
                  <span>
                    <i class="fas fa-tree" style="margin-right: 0.3rem"></i>
                    Jardim
                  </span>
                  <span>
                    <i class="fas fa-car" style="margin-right: 0.3rem"></i>
                    Estacionamento grátis
                  </span>
                  <span>
                    <i class="fas fa-wifi" style="margin-right: 0.3rem"></i>
                    Wi-Fi gratuito
                  </span>
                  <span>
                    <i class="fas fa-dumbbell" style="margin-right: 0.3rem"></i>
                    Academia
                  </span>
                  <span>
                    <i class="fas fa-smoking" style="margin-right: 0.3rem"></i>
                    Área específica para fumantes
                  </span>
                </p> -->
              </div>
            </div>

            <!-- <div class="tour_details_boxed">
              <h3 class="heading_theme">Apartamentos</h3>
              <div class="tour_details_boxed_inner">
                <table>
                  <thead>
                    <tr>
                      <th style="width: 70%">Tipo de apartamento</th>
                      <th style="text-align: center">Acomoda</th>
                      <th style="text-align: center">Preço diaria</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <h5 class="text-primary">
                            <strong>Apartamento Superior</strong>
                          </h5>
                          <div class="mt-2 text-secondary">
                            <div>
                              <span class="d-block">
                                <strong>Quarto 2:</strong> 1 cama de casal 1
                                cama de solteiro
                              </span>
                              <span class="d-block">
                                <strong>Sala de estar:</strong> 1 sofa cama
                              </span>
                            </div>
                          </div>
                          <div>
                            <p class="d-flex flex-wrap gap-3 mt-3">
                              <span>
                                <i
                                  class="fas fa-users"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Quartos para famílias
                              </span>
                              <span>
                                <i
                                  class="fas fa-tree"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Jardim
                              </span>
                              <span>
                                <i
                                  class="fas fa-car"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Estacionamento grátis
                              </span>
                              <span>
                                <i
                                  class="fas fa-wifi"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Wi-Fi gratuito
                              </span>
                              <span>
                                <i
                                  class="fas fa-dumbbell"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Academia
                              </span>
                              <span>
                                <i
                                  class="fas fa-smoking"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Área específica para fumantes
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td style="text-align: center">2</td>
                      <td style="text-align: center">R$ 2.000,00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->
            <div v-if="!isCamping" class="tour_details_boxed">
              <h3 class="heading_theme">Regras de acomodação</h3>
              <div class="tour_details_boxed_inner regra" v-if="!hotel?.rules">
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-sign-in-alt"></i>Entrada
                  </li>
                  <li class="flex-wrap">Das 06h00 ás 22h00</li>
                </ul>
                <ul class="d-flex">
                  <li><i class="fas fa-sign-out-alt"></i>Saída</li>
                  <li class="flex-wrap">Até 11h00</li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-strikethrough"></i>
                    Cancelamento/pré-pagamento
                  </li>
                  <li>
                    As políticas de cancelamento e pré-pagamento variam de
                    acordo com o tipo de acomodação. Adicione as datas da sua
                    estadia e visualize a política do quarto requerido.
                  </li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-child"></i> Crianças e camas
                  </li>
                  <li class="flex-wrap">
                    <span
                      style="margin-bottom: 0.5rem; display: block; width: 100%"
                    >
                      <strong> Políticas para crianças </strong>
                    </span>
                    Crianças de qualquer idade são bem-vindas. Para ver os
                    preços e as informações de ocupação certos, informe quantas
                    crianças fazem parte do seu grupo e a idade delas.

                    <span
                      style="margin: 0.5rem 0px; display: block; width: 100%"
                    >
                      <strong> Políticas para berços e camas extras </strong>
                    </span>
                    Não há capacidade para berços nesta acomodação. Não há
                    capacidade para camas extras nesta acomodação.
                  </li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-male"></i> Sem restrições de idade
                  </li>
                  <li class="flex-wrap">
                    Não há exigência de idade para o check-in
                  </li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-paw"></i> Animais de estimação
                  </li>
                  <li class="flex-wrap">Pets: não permitidos.</li>
                </ul>
              </div>
              <div v-else v-html="hotel?.rules"></div>
            </div>

            <div class="tour_details_boxed" v-if="hotel?.whatToTake">
              <h3 class="heading_theme">O que levar</h3>
              <div v-html="hotel?.whatToTake"></div>
            </div>

            <div class="tour_details_boxed" v-if="hotel?.amenities">
              <h3 class="heading_theme">Comodidades</h3>
              <div style="display: block; margin: .5rem 0px;" v-for="comodidade in hotel?.amenities" :key="comodidade">
                - {{ comodidade }}
              </div>
            </div>

            <div class="tour_details_boxed">
              <h3 class="heading_theme">Endereço</h3>
              <div class="map_area">
                <div v-if="hotel?.map" v-html="hotel?.map"></div>
                <iframe
                  v-else
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28402.27348085063!2d-48.5244877530341!3d-27.147347433701796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8a411a138c881%3A0x861731dfacd2e935!2sCentro%2C%20Bombinhas%20-%20SC!5e0!3m2!1spt-BR!2sbr!4v1706316916896!5m2!1spt-BR!2sbr"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-contact sticky" style="margin-top: 5.7rem">
          <div class="tour_details_right_sidebar_wrapper">
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed py-0 px-0">
                <div
                  class="tour_details_right_box_heading bg-primary d-flex justify-content-center align-items-center"
                >
                  <h4 class="py-3 text-white">INFORMAÇÕES DA RESERVA</h4>
                </div>

                <div v-if="account && !isCamping" class="tour_package_details_bar_list pb-4">
                  <ul>
                    <li
                      style="
                        margin: -1.3rem 0px -1rem 0px;
                        background-color: #d2dae2;
                      "
                      class="pb-3 px-3"
                    >
                      <div class="text-secondary" style="font-size: 0.85rem">
                     <span v-if="account?.cupom"> Min de <strong>3</strong> diarias e max de <strong>5</strong></span>
                     <br v-if="account?.cupom" />
                        <strong class="text-body">
                          {{ calcularDiarias(dateInitial, dateFinal) }}
                        </strong>
                        <span> diárias para</span>
                        <EditCountHotel
                          style="margin-top: 7px; margin-left: 2px"
                        />
                        <br />
                        <div class="col-12" style="cursor: pointer">
                          <div style="cursor: pointer">
                            <div class="flight_Search_boxed date_flex_area">
                              <div style="cursor: pointer" class="Journey_date">
                                <p>de</p>
                                <input
                                  v-model="dateInitial"
                                  @change="checkDateValidity(hotel.blocked_dates)"
                                  style="
                                    font-size: 1rem;
                                    cursor: pointer;
                                    width: 90%;
                                  "
                                  type="date"
                                />
                              </div>
                              <div style="cursor: pointer" class="Journey_date">
                                <p>até</p>
                                <input
                                  v-model="dateFinal"
                                  @change="checkDateValidity(hotel.blocked_dates)"
                                  style="
                                    font-size: 1rem;
                                    cursor: pointer;
                                    width: 90%;
                                  "
                                  type="date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="px-3">
                      <div class="text-secondary">
                        <div class="tour_package_bar_price" v-if="account?.plan_unlocked && validPlan(account?.plan, hotel.plan, account?.plan_unlocked)">
                          <h6>
                            <del>{{
                              hotel.price?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</del>
                          </h6>
                          <h3>
                            {{
                              hotel.member_price?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}
                            <sub>/Diaria e por pessoa</sub>
                          </h3>
                        </div>
                        <div class="tour_package_bar_price" v-else>
                          <h3>
                            {{
                              hotel.price.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}
                            <sub>/Diaria e por pessoa</sub>
                          </h3>
                        </div>
                        <h3 class="text-body mt-1">
                          <small> Valor total: </small>
                          <strong v-if="account.plan && !account.plan_unlocked || !account.plan || !validPlan(account.plan, hotel.plan, account.plan_unlocked)">
                            {{
                             ( hotel.price *
                              calcularDiarias(dateInitial, dateFinal) *
                              number)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                            }}
                          </strong>
                          <strong v-else>
                            {{
                             ( hotel.member_price *
                              calcularDiarias(dateInitial, dateFinal) *
                              number)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                            }}
                          </strong>
                        </h3>
                      </div>
                    </li>
                  </ul>
                  <div class="d-grid gap-2 mt-4">
                    <button
                      class="btn btn-success mx-2"
                      type="button"
                      style="border-radius: 50px"
                      :disabled="account?.cupom
                      && calcularDiarias(dateInitial, dateFinal) < 3
                      || account?.cupom
                      && calcularDiarias(dateInitial, dateFinal) > 5
                      || !account?.cupom
                      && calcularDiarias(dateInitial, dateFinal) < 3
                      || !number
                      "
                      @click="confirmReservation()"
                    >
                      CONFIRMAR RESERVA
                    </button>
                  </div>
                </div>
                <div v-if="!account" class="tour_package_details_bar_list pb-4 text-center">
                  <span> Reserve hoje mesmo esta acomodação! </span>

                  <button
                      class="btn btn-primary mx-2 mt-3"
                      type="button"
                      style="border-radius: 10px"
                      @click="$router.push({name: 'become-vendor'})"
                  >
                   Acesse o painel e  <br /> faça uma reserva online.
                  </button>
                </div>
                 <div v-if="isCamping" class="tour_package_details_bar_list pb-4 text-center">
                  <span> Faça sua solicitação <strong> agora mesmo! </strong> </span>

                  <button
                      class="btn btn-primary mx-2 mt-3"
                      type="button"
                      style="border-radius: 10px"
                      @click.prevent="generateLink()"
                  >
                    Clique aqui e faça sua solicitação
                  </button>

                  <div style="text-center; font-size: .8rem; padding: 0px 1rem; margin-top: 2rem">
                  <span>
                    A solicitação de reserva deve ser feita
                    com até <br /> <strong> 10 dias de antecedência. </strong>
                  </span>
                  <span style="margin: 1rem 0px; display: block">
                    <strong> Importante: </strong> <br />
                    A solicitação não garante sua reserva.
                    Assim que o Hotel verificar a disponibilidade, encaminharemos um e-mail para sua avaliação.
                  </span>
                  <span>
                    Caso tenha interesse na disponibilidade apresentada, será necessário enviar a confirmação respondendo o e-mail recebido.
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Contato</h3>
                </div>
                <div
                  class="tour_package_details_bar_list first_child_padding_none"
                >
                  <form @submit.prevent>
                    <div class="mb-3">
                      <label for="mensagem" class="form-label">Mensagem</label>
                      <input
                        type="textarea"
                        class="form-control"
                        id="mensagem"
                        required
                        v-model="form.mensagem"
                        aria-describedby="emailHelp"
                      />
                      <div id="emailHelp" class="form-text">
                        Mensagem será enviada pelo whatsapp
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="nome" class="form-label">Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="form.nome"
                        id="nome"
                        :focus="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="nome" class="form-label">E-mail</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="form.email"
                        id="nome"
                      />
                    </div>
                    <button
                      type="submit"
                      :disabled="!form.email || !form.nome || !form.mensagem"
                      class="btn btn-success"
                      style="width: 100%"
                      @click="submitWhatts()"
                    >
                      Enviar
                    </button>
                  </form>

                  <ul style="margin-top: 1.5rem">
                    <li>
                      <i class="fa fa-envelope" style="font-size: 1rem"></i
                      >contato@clubekaboo.com.br
                    </li>
                    <li>
                      <i class="fa fa-phone" style="font-size: 1rem"></i> 71
                      41 3225-3647
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { hotelsStore } from "@/store/hotels.store";
import EditCountHotel from "@/components/hotel/EditCountHotel";
import { accountStore } from "@/store/account.store";
import { GET_HOTEL, CREAT_INVITE, CREAT_TITLE, GET_TITLE } from "@/root/firebase";
import { notify } from "@kyvg/vue3-notification";
import moment from "moment";
import 'moment/locale/pt-br'
moment.locale('pt-br');

const { number, anumber, cnumber, bnumber, hotel, reservationRequest } = storeToRefs(
  hotelsStore()
);

let currentHotel = null;
const { setAccount } = accountStore();
const { account } = storeToRefs(accountStore());
const route = useRoute();
const router = useRouter();
const dateInitial = ref(moment().format("yyyy-MM-DD"));
const dateFinal = ref(moment().add(1, "days").format("yyyy-MM-DD"));
const titleData = ref('');

onMounted(() => {
  number.value = 0;
  anumber.value = 0;
  cnumber.value = 0;
  bnumber.value = 0;
  // CREAT_TITLE('ouro')
  // CREAT_TITLE('bronze')
  // CREAT_TITLE('prata')

  if (!account.value) return;
  
  const title = account.value.plan_indentify;

  GET_TITLE(title).then(_title => {
       titleData.value = _title.data();
       if (!account.value) return;
       if (titleData.value?.validy) {
          account.value.plan_unlocked = titleData.value?.unlocked;
          account.value.plan_valid = titleData.value?.validy;
       }
       
       setAccount(account.value);
    })
})

  const checkDateValidity = (blockedDates) => {
  // // Convertendo datas para objetos Date
  // const inicio = new Date(dateInitial.value);
  // const fim = new Date(dateFinal.value);

  // // Verificando se as datas estão bloqueadas
  // for (const blockedDate of blockedDates) {
  //   const blocked = new Date(blockedDate);
  //   if (inicio <= blocked && blocked <= fim) {
  //     notify({
  //       title: "Data inválida",
  //       text: `A data selecionada está bloqueada. Por favor, escolha outra data.`,
  //       type: "error",
  //       duration: 7000,
  //     });
  //     dateInitial.value = moment().format("YYYY-MM-DD");
  //     dateFinal.value = moment().add(1, "days").format("YYYY-MM-DD");
  //     return false;
  //   }
  // }
  // return true;
};

const updatePriceBasedOnDateRanges = (dateRanges) => {
  if (!dateRanges) return;
  // Data atual
  const today = new Date();


  // Percorrer todos os intervalos de datas
  for (const range of dateRanges) {
    const { start, end, price, memberPrice } = range;

    // Converter start e end para objetos Date
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Verificar se a data atual está dentro do intervalo
    if (today >= startDate && today <= endDate) {
      hotel.value.price = Number(price) || hotel.value.price;
      hotel.value.member_price = Number(memberPrice) || hotel.value.member_price;
      break; // Parar ao encontrar o primeiro intervalo válido
    }
  }
}

const gethotel = async () => {
  const { indentify } = route.params || {};

  if (!indentify) return;

  const h = await GET_HOTEL(indentify);

  hotel.value = h.data();
};

gethotel();

const validPlan = (plan, hotelPlan, plan_unlocked) => {

  if (!plan_unlocked) return false;
  
  const plans = [
    'bronze',
    'prata',
    'ouro'
  ]

  let level = 1;
  let accountLevel = 0;

  if (hotelPlan == 'bronze') level = 1;
  if (hotelPlan == 'prata') level = 2;
  if (hotelPlan == 'ouro') level = 3;
  
  if (plan == 'bronze') accountLevel = 1;
  if (plan == 'prata') accountLevel = 2;
  if (plan == 'ouro') accountLevel = 3;

  if (accountLevel >= level) {
    return true;
  } 
  return false;
}

const confirmReservation = () => {
  const hr = ref(hotel.value);
  const totalPerson = number.value;

  if (totalPerson >= hotel.value.minPerson && totalPerson <= hotel.value.person) {

  if (!dateInitial.value || !dateFinal.value || !number.value) return;

  hr.value["entryDate"] = dateInitial.value;
  hr.value["departureDate"] = dateFinal.value;
  hr.value["status"] = "Pendente";
  hr.value["formOfPayment"] = "Pix";
  hr.value["hotel_indentify"] = hr.value.indentify;
  hr.value['hotel_reservation'] = hr.value.indentify;
  hr.value["indentify"] = `REA${gerarIdAleatorio()}`;
  hr.value["totalPerson"] = number.value;
  (hr.value["person"] = [anumber.value, cnumber.value, bnumber.value]),
    (hr.value["dates"] = calcularDiariasEIntervalo(
      dateInitial.value,
      dateFinal.value
    ));
  hr.value["total"] =
  validPlan(account.value.plan, hr.value.plan, account.value?.plan_unlocked)
      ? 
        (
          hotel.value.member_price *
            calcularDiarias(dateInitial.value, dateFinal.value)
        ) * number.value
      : (
          hotel.value.price *
            calcularDiarias(dateInitial.value, dateFinal.value)
        ) * number.value;
  hr.value = hr.value;
  reservationRequest.value = hr.value;

  router.push("/room-booking");
  } else {
    notify({
        title: "Numero de pessoas inválido",
        text: `Minimo de ${hotel.value.minPerson} pessoas e maximo ${hotel.value.person}`,
        type: "error",
        duration: 7000,
    });
  }
};

const isCamping = computed(() => {
 return hotel.value.category == 'Hotel' || hotel.value.category == 'Pousada' || hotel.value.category == 'Parque' || hotel.value.category == 'Clube';
})

const gerarIdAleatorio = () => {
  const letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numeros = "0123456789";

  let id = "";

  // Adicionando 6 letras aleatórias
  for (let i = 0; i < 6; i++) {
    const letraAleatoria = letras.charAt(
      Math.floor(Math.random() * letras.length)
    );
    id += letraAleatoria;
  }

  // Adicionando 4 números aleatórios
  for (let i = 0; i < 4; i++) {
    const numeroAleatorio = numeros.charAt(
      Math.floor(Math.random() * numeros.length)
    );
    id += numeroAleatorio;
  }

  return id;
};

const calcularDiariasEIntervalo = (dataInicial, dataFinal) => {
  // Convertendo as datas para objetos Date
  const inicio = new Date(dataInicial);
  const fim = new Date(dataFinal);

  // Calculando a diferença em milissegundos
  const diferencaEmMilissegundos = fim - inicio;

  // Convertendo a diferença para dias
  const dias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

  // Arredondando para o número inteiro mais próximo
  const numeroDiarias = Math.round(dias);

  // Criando o array para armazenar as datas do intervalo
  const datasDoIntervalo = [];

  // Adicionando as datas ao array
  for (let i = 0; i <= numeroDiarias; i++) {
    const data = new Date(inicio);
    data.setDate(inicio.getDate() + i);
    datasDoIntervalo.push(data.toISOString().split("T")[0]);
  }

  return datasDoIntervalo;
};


const slideIndex = ref(0);

const form = {
  nome: "",
  mensagem: "gostaria de mais informações, vi no clube kaboo",
  email: "",
  telefone: "",
};

const move = (n) => {
  const gallery = hotel.value.photos.filter(photo => photo);
  if (gallery.length <= slideIndex.value + n) {
    slideIndex.value = 0;
  } else if (slideIndex.value + n < 0) {
    slideIndex.value = gallery.length - 1;
  } else {
    slideIndex.value += n;
  }
};
const currentSlide = (index) => {
  slideIndex.value = index;
};

const submitWhatts = () => {
  const form = form;
  const msg = `${form.nome ? "Sou " + form.nome : ""}, ${form.mensagem} ${
    form.email ? "meu e-mail: " + form.email : ""
  }`;

  const url = `https://api.whatsapp.com/send?phone=+554184122385&text=${msg}`;

  window.open(url, "_blank");

  form.email = "";
  form.nome = "";
};

const calcularDiarias = (dataInicial, dataFinal) => {
  if (!dataInicial || !dataFinal) return 0;
  // updatePrices(dataInicial, dataFinal, hotel.value.dateRanges);
  // Convertendo as datas para objetos Date
  const inicio = new Date(dataInicial);
  const fim = new Date(dataFinal);

  // Calculando a diferença em milissegundos
  const diferencaEmMilissegundos = fim - inicio;

  // Convertendo a diferença para dias
  const dias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

  // Arredondando para o número inteiro mais próximo
  const numeroDiarias = Math.round(dias)+1;

  return numeroDiarias;
};

watch(
  hotel,
  () => {
    const { price, member_price } = hotel.value;
    currentHotel = { price, member_price };
    updatePrices(dateInitial.value, dateFinal.value, hotel.value.dateRanges);
  },
  { once: true }
)

watch(dateInitial, () => {
  updatePrices(dateInitial.value, dateFinal.value, hotel.value.dateRanges);
})

watch(dateFinal, () => {
  updatePrices(dateInitial.value, dateFinal.value, hotel.value.dateRanges);
})

const updatePrices = (dateInitial, dateFinal, dateRanges) => {
  if (!dateRanges) return;
  // Convert dateInitial and dateFinal to Date objects
  const initial = new Date(dateInitial);
  const final = new Date(dateFinal);

  let price = 0;
  let member_price = 0;

  // Iterate over dateRanges
  dateRanges.forEach(range => {

    const rangeStart = new Date(range.start);
    const rangeEnd = new Date(range.end);

    // Check if the date range overlaps with the provided date range
    if (rangeStart <= final && rangeEnd >= initial) {
      price = Number(range.price) || 0;
      member_price = Number(range.memberPrice) || 0;
      console.log('range.member_price', range.memberPrice);
    }
  });

  hotel.value.price = Number(price) || currentHotel.price;
  hotel.value.member_price = Number(member_price) || currentHotel.member_price;
}

const isLoged = ref(window.localStorage.getItem("token"));


const generateLink = () => {
  const title = hotel?.value.name || '';
  const drescription = `Quero fazer uma reserva para ${hotel?.value.category || ''} - ${title}  `;
  
  // Função para gerar a URL de mensagem do WhatsApp
    const numeroTelefone = "554132253647"; // Número do WhatsApp com DDI e DDD
    const msg = `${drescription}`;
    const msgCod = encodeURIComponent(msg); // Codifica para a URL
    const link = hotel?.value.campLink || `https://wa.me/${numeroTelefone}?text=${msgCod}`;

    window.open(link, "_blank");
}

</script>

<style scoped>
@media all and (min-width: 900px) {
  .col-contact {
    margin-top: 7rem;
  }
}

.regra li {
  width: 100%;
}

.regra ul {
  flex-basis: 10rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0px;
}
.regra i {
  font-size: 1.3rem;
  margin-right: 0.5rem;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.person {
  font-size: .8rem;
  opacity: 0.5;
  text-align: center;
}
</style>
