<template>
    <div>
        <footer id="footer_area" class="bg-primary text-white">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>Endereço</h5>
                        </div>
                        <div>
                            <p style="color: #fff">
                                Clube Kaboo aprt - hotéis e turismo <br />
                                Sede Rua Maria Bonatto Maranhão 443, Afonso Pena
                                <br /> São José dos Pinhais
                                <br /> Cep: 83.045-140
                            </p>
                            <p style="margin-top: 1rem; color: #fff">
                                CNPJ:  13.488.808/0001-01
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>Precisa de ajuda?</h5>
                            <p style="color: #fff">
                                Atendimento segunda a sexta 9:00h a 12h e 13:30 as 18h
                                (Exceto feriados)
                            </p>
                        </div>
                        <div class="footer_first_area text-white" style="margin-left: -.6rem">
                            <div class="footer_inquery_area">
                                <h5>Telefone</h5>
                                <h3> <a href="tel:+55 3333-3333" class="text-white">+55 3333-333</a></h3>
                            </div>
                            <div class="footer_inquery_area text-white">
                                <h5>E-mail</h5>
                                <h3> <a href="mailto:contato@clubekaboo.com.br" class="text-white">
                                    contato@clubekaboo.com.br
                                    </a>
                                </h3>
                            </div>
                            <div class="footer_inquery_area">
                                <h5>Siga-nos</h5>
                                <ul class="soical_icon_footer">
                                    <li><a href="https://www.facebook.com/people/Clube-Kaboo-Apart-Hot%C3%A9is-e-Turismo/61562756399696/?mibextid=ZbWKwL" target="_blank"><i class="fab fa-facebook text-white"></i></a></li>
                                    <li><a href="https://www.instagram.com/clube.kaboo.ferias/?igsh=MWY1YWNtajJ2aTlpOQ%3D%3D" target="_blank"><i class="fab fa-instagram text-white"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>Empresa</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><router-link to="#" class="text-gray-200 font-weight-light">Sobre nós</router-link></li>
                                <li><router-link to="#">Depoimentos</router-link></li>
                                <li><router-link to="#">FAQS</router-link></li>
                                <li><router-link to="#">Termo de serviço</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>Support</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><router-link to="#">Conta</router-link></li>
                                <li><router-link to="#">FAQ</router-link></li>
                                <li><router-link to="#">Depoimentos</router-link></li>
                                <li><router-link to="#">Contato</router-link></li>
                                <li><router-link to="#"> Programa de Afiliados </router-link></li>
                                <li><router-link to="#">Politica de privacidade</router-link></li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </footer>
        <div class="copyright_area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="copyright_left">
                            <p>Copyright © 2024 todos os direitos reservados</p>
                        </div>
                    </div>
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="copyright_right">
                            Club Kaboo
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="https://wa.me/554132253647?text=Olá!%20Gostaria%20de%20mais%20informações." class="whatsapp-button" target="_blank">
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" />
        </a>
    </div>
</template>
<script>

export default {
    name: 'Footer'
}
</script>

<style>
  .whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }

  .whatsapp-button img {
    width: 60px;
    height: 60px;
  }
</style>
