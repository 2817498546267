<template>
  <Header />
  <!-- Banner Area -->
  <Banner v-if="hasBanner.includes($route.name)" />
  <router-view />
  <Footer />
</template>
  
<script>
import Header from "@/components/Header.vue";
import Banner from '@/components/home/Banner.vue'
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  data() {
    return {
      hasBanner: ['home'],
    };
  },
  components: {
    Header,
    Banner,
    Footer,
  },
};
</script>