<template>
  <section id="explore_area" class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>1 encontrado</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-hotels"
                  role="tabpanel"
                  aria-labelledby="nav-hotels-tab"
                >
                  <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="theme_common_box_two img_hover">
                        <div class="theme_two_box_img">
                          <router-link to="/hotel-details">
                            <img
                              src="../../assets/hoteis/a1.jpg"
                              style="height: 200px; width: auto"
                              alt="img"
                            />
                          </router-link>
                          <p>
                            <i class="fas fa-map-marker-alt"></i>Bombinhas
                            Centro
                          </p>
                          <div class="discount_tab">
                            <span>50%</span>
                          </div>
                        </div>
                        <div class="theme_two_box_content">
                          <h4>
                            <router-link to="/hotel-details"
                              >Casa Marinete</router-link
                            >
                          </h4>
                          <p v-if="!userIsAuthenticated">
                            <span class="review_rating"
                              ><i class="fas fa-money-bill-wave"></i>
                              R$--,00 por pessoa
                            </span>
                          </p>
                          <h3 class="pt-2">
                            R$--
                            <span
                              >por
                              {{
                                userIsAuthenticated ? "pessoa" : "associado"
                              }}</span
                            >
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref } from "vue";
import { Modal } from "bootstrap";

const userIsAuthenticated = window.localStorage.getItem("token");

const modalRef = ref(null);
const dependent = ref(null);

const getDependent = (dep) => {
  dependent.value = dep;
};

const closed = () => {
  dependent.value = null;
  Modal.getInstance(modalRef.value)?.hide();
};
</script>
<style src="@vueform/slider/themes/default.css">
</style>