<template>
    <div style="padding-top: 2.5rem"></div>
    <!-- Hotel Search Areas -->
    <HotelSearch />
    <!--Related hotel packages Area -->
    <!-- <RelatedHotel /> -->
</template>
<script>
import HotelDetailBanner from '@/components/hotel/HotelDetailBanner.vue'
import HotelSearch from '@/components/hotel/HotelSearch.vue'
import HotelCard from '@/components/hotel/HotelCard.vue'
import RelatedHotel from '@/components/hotel/RelatedHotel.vue'
import Cta from '@/components/home/Cta.vue'

export default {
    name: "HotelDetailsView",
    components: {
        HotelDetailBanner, HotelSearch, RelatedHotel, Cta, HotelCard
    }
};
</script>
