<template>
  <section id="vendor_form_area" class="section_padding_bottom">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="vendor_form_heading text-left">
            <h2 class="d-block mt-4"> Minha Conta </h2>
          </div>
        </div>
        <div class="col-md-12">
          <div class="vendor_form">
            <div class="tour_booking_form_box">
              <form action="!#" id="tour_bookking_form_item">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.name"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Nome Completo*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.phone"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Celular*"
                        id="phone"
                        v-maska
                        data-maska="## # ####-####"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      v-model="dataRegister.cpf"
                      type="text"
                      class="form-control bg_input"
                      placeholder="CPF*"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      v-model="dataRegister.rg"
                      type="text"
                      class="form-control bg_input"
                      placeholder="RG*"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      v-model="dataRegister.profession"
                      type="text"
                      class="form-control bg_input"
                      placeholder="Profissão"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      v-model="dataRegister.spouse"
                      type="text"
                      class="form-control bg_input"
                      placeholder="Cônjuge"
                    />
                  </div>
                </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.email"
                        type="email"
                        class="form-control bg_input"
                        placeholder="E-mail"
                      />
                    </div>
                  </div>

                  <h3 class="d-block mb-2">
                      Endereço
                  </h3>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.cep"
                        type="text"
                        class="form-control bg_input"
                        placeholder="CEP"
                        v-maska
                        data-maska="#####-###"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.cidade"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Cidade"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.bairro"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Bairro"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.logradouro"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Endereço"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.uf"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Estado"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="dataRegister.numero"
                        type="text"
                        class="form-control bg_input"
                        placeholder="Numero"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="booking_tour_form_submit pt-4">
              <button
                class="btn btn-primary my-4"
                @click="editSave()"
                :disabled="!validation()"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted , reactive, ref } from "vue";
import { useRouter } from "vue-router";
import {DB_UPDATE_PROFILE, Timestamp, CREATE_USER, GET_TITLE } from "@/root/firebase";
import { notify } from "@kyvg/vue3-notification";
import { vMaska } from "maska";

const router = useRouter();
const titleData = ref(null);
const msgTitleError = ref(false);
const t = ref('');
const validy_title = ref(false);
let dataRegister = ref({
  plan_indentify: '',
  plan: '',
  plan_valid: true,
  plan_unlocked: false,
  name: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  city: "",
  contactPreference: "nenhum",

  spouse: '',
  profession: '',
  cpf: '',
  rg: '',

  cep: '',
  cidade: '',
  logradouro: '',
  bairro: '',
  uf: '',
  numero: '',
});

onMounted(() => {
  console.log('localStorage.getItem', localStorage.getItem('AccountStore'))
  const { account } = JSON.parse(localStorage.getItem('AccountStore')) || {};
  console.log('account', account)
  if (!account) return;
  dataRegister.value = account;
  console.log('dataRegister', dataRegister.value)
})

const getTitle = () => {
  GET_TITLE(t.value).then(_title => {
     titleData.value = _title.data();
     if (titleData.value?.validy) validy_title.value = true;
     else msgTitleError.value = true;
  })
}

const validation = () => {
  if (
    dataRegister.value.name &&
    dataRegister.value.email &&
    dataRegister.value.phone &&
    dataRegister.value.contactPreference &&
    dataRegister.value.cpf && 
    dataRegister.value.profession &&
    dataRegister.value.rg
  )
    return true;

  return false;
};

const editSave = async () => {
  try {
    await DB_UPDATE_PROFILE(dataRegister.value.indentify, dataRegister.value);
    notify({
        title: "Sucesso ao editar perfil",
        text: 'Perfil editado com sucesso!',
        type: "success",
        duration: 7000,
    });
  } catch (error) {
      notify({
        title: "Error ao cadastrar",
        text: error,
        type: "error",
        duration: 7000,
      });
  }
};
</script>