<template>
    <div class="container modal-body">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="vendor_form">
            <div>
              <form @submit.prevent="registerCupom()"  enctype="multipart/form-data">
                <h4 class="mb-3">Upload de Imagem</h4>
    
                <div class="row g-2">
                    <div class="col-lg-6 box-file" v-for="(item, index) in 1" :key=item>
                      <span v-show="formData?.photos[index]" @click="removeFile(index)">
                        <span class="btn btn-danger remove-file">
                          <i class="fas fa-times"></i>
                        </span>
                      </span>
                        <img v-if="formData?.photos && formData?.photos[index]" :src="formData.photos[index]" class="thumbnail">
                        <input type="file" class="form-control" @change="handleFileChange($event, index)">
                    </div>

                  <div class="col-lg-12">
                    <div class="form-group has-valindentifyation">
                      <label for="f-name">Nome</label>
                      <input
                        autocomplete="off"
                        v-model="formData.name"
                        type="text"
                        class="form-control"
                        placeholder="Nome"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="f-name">Link do banner para campanha</label>
                      <input
                        v-model="formData.campBannerLink"
                        type="text"
                        class="form-control"
                        placeholder="Link de campanha"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                  <div class="form-group mb-3 mt-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="formData.enabled"
                        id="flexCheckChecked"
                        checked
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Habilitado
                      </label>
                    </div>
                  </div>
                </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                      Cancelar
                  </button>
                  <button type="submit" :disabled="loadingImage" class="btn btn-primary">
                    {{ loadingImage ? 'Carregando aguarde...' : prop.dependent ? 'Editar Cadastro' : 'Inserir Cadastro' }}
                  </button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script setup>
  import { reactive, defineEmits, watch, ref } from "vue";
  import { Timestamp, DB_SET_CUPOM, storage, storageRef, uploadBytes, getDownloadURL, formatText } from "@/root/firebase";
  import { notify } from "@kyvg/vue3-notification";
  import { VueEditor } from "vue3-editor";

  const emit = defineEmits(["closed", 'setCupom']);
  const prop = defineProps(['dependent']);
  const amenitie = ref('');
  
  const loadingImage = ref(false)
  
  const formData = reactive({
    name: "",
    campBannerLink: "",
    imageUrl: "",
    photos: [],
    enabled: true,
  });
  
  watch(
    () => prop.dependent,
    (dependent) => {
      if (dependent) {
        formData['campBannerLink'] = dependent.campBannerLink;
        formData['name'] = dependent.name;
        formData['imageUrl'] = dependent.imageUrl;
        formData['date_created'] = dependent.date_created;
        formData['photos'] = dependent.photos;
        formData['enabled'] = dependent.enabled;
      } else {
        formData['campBannerLink'] = '';
        formData['name'] = '';
        formData['imageUrl'] = '';
        formData['date_created'] = '';
        formData['photos'] = [];
        formData['enabled'] = true;
      }
    }
  )
  
  const addDependent = () => {
    emit("closed");
  };

    const handleFileChange = (event, index) => {
        if (!event) return;
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                submitphoto(reader.result, index)
            };
            reader.readAsDataURL(file);
    };

    const removeFile = (index) => {
      formData.photos[index] = null;
    }

    const dataURItoFile = (dataURI, filename) => {
            const arr = dataURI.split(',');
            if (!arr.length) return;
            let mime = arr[0].match(/:(.*?);/);
            if (!mime) return;
            mime = mime[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
    };

    const submitphoto = async (image, index) => {
                if (image) {
                    const file = dataURItoFile(image, `image_${Date.now()}.png`);
                    if (!file) return;

                    const storageRefs = storageRef(storage, `images/${file.name}`);

                    if (!file) return;

                    try {
                        const snapshot = await uploadBytes(storageRefs, file);
                        const url = await getDownloadURL(snapshot.ref);
                        console.log('URL completa do arquivo carregado:', url);
                        formData.photos[index] = url;
                    } catch (error) {
                        console.error('Erro ao carregar o arquivo:', error);
                    }
                }
        };

    const submitphotos = async () => {
        loadingImage.value = true;

        for (const image of formData.photos) {
            let index = 0;
                if (image) {
                    const file = dataURItoFile(image, `image_${Date.now()}.png`);
                    if (!file) return;

                    const storageRefs = storageRef(storage, `cupons/${file.name}`);

                    if (!file) return;

                    try {
                        const snapshot = await uploadBytes(storageRefs, file);
                        const url = await getDownloadURL(snapshot.ref);
                        console.log('URL completa do arquivo carregado:', url);
                        formData.photos[index] = url;
                    } catch (error) {
                        console.error('Erro ao carregar o arquivo:', error);
                    }
                }
                index += 1;
            }
        };

 const parseCurrency = (value) => {
  if (typeof value !== 'string') {
    return value;
  }

  // Remove pontos (milhares) e substitui vírgula por ponto
  value = value.replace(/\./g, '').replace(',', '.');
  
  return parseFloat(value);
}
  
  const registerCupom = async () => {
    function generateRandomIdentifier(length = 20) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let identifier = '';
        for (let i = 0; i < length; i++) {
            identifier += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return identifier;
    }

    let identifier = generateRandomIdentifier();
    if (prop.dependent) identifier = prop.dependent['indentify'];

    formData['indentify'] = identifier;

    const data = JSON.parse(JSON.stringify(formData));
    data.date_created = Timestamp.fromDate(new Date())

    // await submitphotos()
    loadingImage.value = true;
  
    DB_SET_CUPOM(identifier, data)
      .then(async (hotel) => {
        emit("setCupom", data);
        addDependent()
      })
      .catch((error) => {
        console.error("Erro ao criar cupom:", error);
        notify({
          title: "Error ao cadastrar",
          text: 'Não conseguimos criar um novo cupom',
          type: "error",
          timeout: 3000,
        });
      }).finally(() => {
        loadingImage.value = false;
      });
  };
</script>

<style scoped>
.box-file {
  position: relative;
}
.remove-file {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
</style>